import { useNavigate } from 'react-router-dom';
import FaildIcon from 'src/assets/images/ResultPayment/faild.png';

const FaildPage = () => {
  const navigate = useNavigate();
  const backToDeedDex = () => {
    navigate('/settings');
  };
  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-gradient-to-br from-blue-500 to-orange-500">
      <div className="p-8 flex flex-col items-center justify-center bg-white shadow-md rounded-lg gap-4 max-w-screen-sm">
        <img src={FaildIcon} className="w-24 max-md:w-20 max-sm:w-16" alt="Success" />
        <h3 className="text-2xl max-md:text-lg font-semibold">Your subscription was not activated.</h3>
        <p>
          The payment could not be completed, and your subscription has not been activated. Please try again or contact
          support for assistance.
        </p>
        <button className="p-4 bg-primary rounded-lg text-white" onClick={backToDeedDex}>
          Back To DeedDex
        </button>
      </div>
    </div>
  );
};

export default FaildPage;
