import { request } from 'src/core/api/axios';
import { RecentDataType } from 'src/modules/Queries/api';

export async function getRecentFiles(formData: RecentDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/recent_files', formData);

    return data;
  } catch (error: any) {
    if (error.response) {
      console.error('Status Code:', error.response.status);
      console.error('Error Data:', error.response.data);
      return error.response?.data?.detail ?? '';
    } else {
      console.error('Error:', error.message);
      return 'Failed Active Trial Subscription';
    }
  }
}
