import Icon from 'src/modules/General/components/Icon';
// import useNotificationsRightColumn from '../RightColumn/index.services';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import { NotificationData, RecentNotificationBody } from 'src/modules/Notifications/api';
import { IconButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'src/core/contexts/_app.context';

interface HeaderMenuProps {
  notificationsList: NotificationData[] | null;
  getStatusNotifications: (notifications: NotificationData[]) => void;
  onGetNotificationsByDateRang: (data: RecentNotificationBody) => Promise<void>;
  fromDate: Dayjs | null;
  // setFromDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  toDate: Dayjs | null;
  // setToDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}
const HeaderMenu = ({
  notificationsList,
  getStatusNotifications,
  onGetNotificationsByDateRang,
  fromDate,
  // setFromDate,
  toDate,
  // setToDate,
}: HeaderMenuProps) => {
  const { dispatch } = useAppContext();

  const onGetNotifications = () => {
    if (fromDate && toDate) {
      const body: RecentNotificationBody = {
        start_date: fromDate?.format('YYYY-MM-DD') ?? '',
        end_date: toDate?.format('YYYY-MM-DD') ?? '',
      };
      onGetNotificationsByDateRang(body);
    }
  };
  const { t } = useTranslation();
  useEffect(() => {
    getStatusNotifications(notificationsList ?? []);
  }, [notificationsList]);
  return (
    <div className="w-full h-15 bg-white rounded-md flex items-center justify-end p-1">
      <div className="flex items-center justify-start flex-row gap-2">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t('From')}
            value={fromDate}
            onChange={(newValue) => {
              dispatch({ type: 'fromDate', value: newValue });
            }}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t('To')}
            value={toDate}
            minDate={fromDate ?? undefined}
            onChange={(newValue) => {
              dispatch({ type: 'toDate', value: newValue });
            }}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
          />
        </LocalizationProvider>
        <IconButton aria-label="search" onClick={onGetNotifications}>
          <Icon name="search" />
        </IconButton>
      </div>
    </div>
  );
};
export default HeaderMenu;
