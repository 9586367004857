import { useEffect, useState } from 'react';
import SuccessIcon from 'src/assets/images/Queries/check.svg';
import useAuth from 'src/core/hooks/useAuth';
import { isValidEmail } from 'src/core/utilities/validations';
import Title from 'src/modules/Authentication/components/Title';
import { introspect, login } from 'src/modules/General/api';
import Input from 'src/modules/General/components/Input';
import Loading from 'src/modules/General/components/Loading';
import Logo from 'src/modules/General/components/Logo';
import Modal from 'src/modules/General/components/Modal';
import { LoginDialogProps } from './index.types';
import { trial } from '../../api';

const LoginModal = ({ open, onClose }: LoginDialogProps) => {
  const { setAuth } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageResponse, setMessageResponse] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeSubscription, setActiveSubscription] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else if (!password) {
      setErrorMessage('Password field cannot be empty.');
    } else {
      setIsLoading(true);
      try {
        const loginRespond = await login({ email: email, password: password });

        const introspectRespond = await introspect();
        const userToken = {
          accessToken: loginRespond.access_token,
          refreshToken: loginRespond.refresh_token,
          tokenType: loginRespond.token_type,
        };

        const userData = {
          isActive: introspectRespond.active,
          userID: introspectRespond.user_id,
          email: introspectRespond.email,
          firstName: introspectRespond.first_name,
          lastName: introspectRespond.last_name,
          username: introspectRespond.username,
          userRole: introspectRespond.user_role,
          expirationDate: introspectRespond.exp,
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('access_token', loginRespond.access_token);
        localStorage.setItem('refresh_token', loginRespond.refresh_token);
        localStorage.setItem('active_trial', '1');

        setAuth({ userToken, userData });

        if (userData.isActive) {
          setEmail('');
          setPassword('');
          setErrorMessage('');
          const data = await trial();

          if (typeof data === 'string') {
            setErrorMessage('Subscription was not activated for you. Please try again.');
            setActiveSubscription(false);
          } else {
            if (data.error_code === 0) {
              setMessageResponse(data.message ?? 'The trial plan was successfully registered.');
              setActiveSubscription(true);
            } else {
              setActiveSubscription(false);
              setErrorMessage(data.detail ?? 'Subscription was not activated for you. Please try again.');
            }
          }

          localStorage.setItem('language', 'en');
        } else {
          setErrorMessage('It seems your account is currently inactive.');
        }
      } catch (e: any) {
        setErrorMessage('Subscription was not activated for you. Please try again.');
      }
      setIsLoading(false);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleSubmit(e);
  };
  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setErrorMessage('');
    setActiveSubscription(false);
  }, [email, password]);
  return (
    <Modal open={open} onClose={onClose} customBoxCss="max-w-4xl">
      <div className="w-full h-full flex flex-row bg-white">
        <div className="w-full flex items-center justify-center px-8 overflow-y-auto max-md:w-full max-sm:px-2">
          {!activeSubscription ? (
            <div className="max-w-640 h-auto flex flex-col gap-y-8 max-xs:w-full">
              <Logo />
              <Title title="Login to DeedDex" />
              <p className="text-base">You must login with your information to continue.</p>
              <form onSubmit={handleSubmit} className="min-w-80 flex flex-col justify-start gap-y-4  max-xs:w-full">
                <Input
                  title="Email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  handleKeyDown={handleKeyDown}
                  placeholder="Enter Your Email"
                  inputStyle="rounded-lg"
                />
                <Input
                  title="Password"
                  eyeIcon={true}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  containerStyle="relative"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  handleKeyDown={handleKeyDown}
                  handleTogglePassword={handleTogglePassword}
                  placeholder="Enter Your Password"
                  inputStyle="rounded-lg"
                />

                {errorMessage && <p className="text-red-600 text-base text-center">{errorMessage}</p>}
                <button
                  disabled={isLoading}
                  type="submit"
                  className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
                  {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                  Login
                </button>
              </form>
            </div>
          ) : (
            <div className="p-4 flex items-center justify-center flex-col gap-2 bg-green-100 shadow-lg rounded">
              <div className="p-3 rounded-full shadow-lg bg-green-600 flex items-center justify-center">
                <img className="w-8 h-8" src={SuccessIcon} alt="success" />
              </div>
              <p className="font-bold text-base text-center">
                The trial plan was successfully registered. You can enjoy the app features for the next 14 days.
              </p>
              <p className="p-2 rounded-lg bg-blue-400 text-white">
                After returning to the app, please refresh the page once.
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
