import Notification from 'src/modules/Notifications/components/Notification';
import { NotificationData } from 'src/modules/Notifications/api';
import Loading from 'src/modules/General/components/Loading';
import NotificationEmpty from 'src/modules/Notifications/components/NotificationEmpty';
import { Dispatch, SetStateAction, useEffect } from 'react';

import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import Snackbar from 'src/modules/General/components/Snackbar';

interface LeftNotificationProps {
  isLoading: boolean;
  notifications: NotificationData[];
  selectedNotifications: number[];
  setSelectedNotifications: Dispatch<SetStateAction<number[]>>;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  removeNotification: () => Promise<void>;
  handleCloseSnackbar: () => void;
  displaySnackbar: {
    display: boolean;
    color: string;
    message: string;
  };

  loadingDelete: boolean;
  closeDeleteNotificationDialog: () => void;
  closeMultiDeleteNotificationDialog: () => void;

  openDeleteNotificationModal: boolean;
  openSingleDeleteNotificationModal: boolean;
  openDialogDeleteNotification: (id: number) => void;
  openDialogMultiDeleteNotification: () => void;
}
const LeftColumn = ({
  isLoading,
  notifications,
  selectAll,
  selectedNotifications,
  setSelectAll,
  setSelectedNotifications,
  removeNotification,
  openDialogDeleteNotification,
  displaySnackbar,
  handleCloseSnackbar,
  loadingDelete,
  closeDeleteNotificationDialog,
  openDeleteNotificationModal,
  openDialogMultiDeleteNotification,
  closeMultiDeleteNotificationDialog,
  openSingleDeleteNotificationModal,
}: LeftNotificationProps) => {
  const { t } = useTranslation();
  // const { dispatch } = useNotificationsContext();
  const areAllSelected = notifications.length > 0 && selectedNotifications.length === notifications.length;
  const handleSelectAll = () => {
    if (areAllSelected) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(notifications.map((n) => n.NotificationID));
    }
    setSelectAll(!areAllSelected);
  };
  const handleSelectNotification = (notificationID: number) => {
    if (selectedNotifications.includes(notificationID)) {
      setSelectedNotifications(selectedNotifications.filter((id) => id !== notificationID));
    } else {
      setSelectedNotifications([...selectedNotifications, notificationID]);
    }
  };
  useEffect(() => {
    setSelectAll(areAllSelected);
  }, [areAllSelected]);
  return (
    <div className="w-full h-full overflow-auto  flex justify-start flex-col gap-y-2">
      {displaySnackbar.display && (
        <Snackbar
          color={displaySnackbar.color}
          open={displaySnackbar.display}
          onClose={handleCloseSnackbar}
          message={displaySnackbar?.message}
        />
      )}
      {!isLoading && notifications?.length > 0 && (
        <div className="flex flex-row items-center gap-2">
          <label className="block relative pl-8 mb-3 top-2 cursor-pointer text-lg select-none">
            <input
              type="checkbox"
              id="all"
              name="all"
              value="allNotification"
              checked={selectAll}
              onChange={handleSelectAll}
              className="absolute opacity-0 cursor-pointer h-0 w-0"
            />
            <span
              className={`absolute top-0 left-0 h-6 w-6 rounded transition-all duration-200
                  ${selectAll ? 'bg-orange-400 border-orange-400' : 'bg-white border-orange-400'} border-2`}>
              <span
                className={`absolute left-1.5 top-0.5 w-1.5 h-3.5 border-white border-r-2 border-b-2 transform
                    ${selectAll ? 'block rotate-45' : 'hidden'}`}
              />
            </span>
            Select All Notifications
          </label>
          <button
            onClick={openDialogMultiDeleteNotification}
            disabled={!selectedNotifications.length}
            className="bg-blue-600 flex items-center text-white py-2 px-4 rounded-lg shadow-lg  focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed">
            Delete
          </button>
        </div>
      )}
      <ConfirmationModal
        open={openDeleteNotificationModal}
        onClose={closeDeleteNotificationDialog}
        text={`Are you sure to delete ${selectedNotifications.length > 1 ? 'notifications?' : 'this notification?'}`}
        buttons={[
          {
            children: t('Remove'),
            variant: 'contained',
            color: 'error',
            onClick: removeNotification,
            endIcon: loadingDelete && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeMultiDeleteNotificationDialog,
          },
        ]}
      />
      {isLoading ? (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <Loading variant="circle" size={44} color="primary" />
        </div>
      ) : notifications?.length > 0 ? (
        notifications?.map((notification: NotificationData, index: number) => (
          <>
            <Notification
              keyIndex={notification.NotificationID}
              openDialogDeleteNotification={openDialogDeleteNotification}
              openDeleteNotificationModal={openSingleDeleteNotificationModal}
              closeDeleteNotificationDialog={closeMultiDeleteNotificationDialog}
              notification={notification}
              notifications={notifications}
              selectedNotifications={selectedNotifications}
              handleSelectNotification={handleSelectNotification}
              key={index}
            />
          </>
        ))
      ) : (
        <NotificationEmpty />
      )}
    </div>
  );
};

export default LeftColumn;
