import { useEffect, useState } from 'react';
import { useAppContext } from 'src/core/contexts/_app.context';
import { checkUserSubscription } from 'src/modules/General/api';
import {
  activeTrial,
  Amount,
  PlandDataType,
  PurchaseDataType,
  Subscription,
  subscriptions,
} from 'src/modules/Settings/api';

const SettingsSubscriptionService = () => {
  const { dispatch } = useAppContext();
  const [message, setMessage] = useState<string>('');
  const [messageList, setMessageList] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isErrorList, setIsErrorList] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [subscriptionsList, setSubscriptionList] = useState<Subscription[]>([]);
  const [plans, setPlans] = useState<PlandDataType[]>([]);

  const ActiveTrialSubscription = async () => {
    try {
      setLoading(true);
      const activeSubscription = await activeTrial();

      setLoading(false);
      if (typeof activeSubscription !== 'string') {
        if (activeSubscription.error_code === 0) {
          setIsSuccess(true);
          setIsError(false);
          const userSubscription = await checkUserSubscription();
          if (typeof userSubscription !== 'string') {
            dispatch({ type: 'subscription', value: userSubscription });
            setMessage('Your subscription has been activated.');
          }
        } else {
          setIsSuccess(false);
          setIsError(true);
          setMessage(activeSubscription.detail ?? 'Your subscription could not be activated. Please try again.');
        }
      } else {
        setIsSuccess(false);
        setIsError(true);
        setMessage(activeSubscription);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setMessage('Your subscription could not be activated. Please try again.');
    }
  };
  const fetchSubscriptions = async () => {
    setLoadingList(true);
    try {
      const data = await subscriptions();
      setLoadingList(false);
      if (typeof data !== 'string') {
        if (data.error_code === 0) {
          setSubscriptionList(data.data);
          generatePlans(data.data);
          setIsErrorList(false);
        } else {
          setSubscriptionList([]);

          setIsErrorList(true);
          setMessageList(data?.message);
        }
      } else {
        setSubscriptionList([]);

        setIsErrorList(true);
        setMessageList(data);
      }
    } catch (error) {
      setIsErrorList(true);
      setLoadingList(false);
      setMessageList('Faild To Fetch Subscriptions.');
    }
  };
  const generatePlans = (subscriptions: Subscription[]) => {
    console.log(subscriptions);
    const plans: PlandDataType[] = [];
    let amount: Amount[];
    let priceId: string;
    for (let i = 0; i < subscriptions.length; i++) {
      const prices = subscriptions[i].prices;
      const name = subscriptions[i].Name;
      const subId = subscriptions[i].SubscriptionID;
      const prodId = subscriptions[i].ProductID;
      amount = [];
      priceId = '';
      for (let j = 0; j < 2; j++) {
        amount.push({
          period: prices[j].Period,
          amount: prices[j].Amount,
        });
        priceId = prices[j].PriceID;
      }
      plans.push({
        name: name,
        subscriptionId: subId,
        priceId: priceId,
        productId: prodId,
        amount: amount,
      });
    }
    setPlans(plans);
    console.log(plans);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);
  return {
    ActiveTrialSubscription,
    loading,
    isError,
    message,
    isSuccess,
    loadingList,
    isErrorList,
    subscriptionsList,
    messageList,
    plans,
  };
};

export default SettingsSubscriptionService;
