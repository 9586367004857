import { request } from 'src/core/api/axios';

export enum SearchEnterpriseMethod {
  SalesTax = 3,
  TemporaryVisa = 4,
}
export type SalesTypes = {
  'Seller Name': string;
  'Transaction numbers': string[];
  QST: string;
  GST: string;
  'Total sales tax': string;
  'Total purchase': string;
};
export interface EnterpriseSearchType {
  method: number;
  search_data: SearchData;
}
type SearchData = {
  project_name?: string;
  name?: string;
  passport_number?: string;
  application_type?: string;
};
export type SectionData = { [key: string]: string };
// Define the type for the entire visa data
export type TemporaryVisaData = {
  General: SectionData;
  'Personal Details': SectionData;
  'Language(s)': SectionData;
  Passport: SectionData;
  'National Identity document': SectionData;
  'US PR card': SectionData;
  'Contact information': SectionData;
};
export interface TemporaryVisaType {
  data?: {
    General: General;
    'Personal Details': PersonalDetails;
    'Language(s)': Languages;
    Passport: Passport;
    'National Identity document': NationalIdentityDocument;
    'US PR card': Card;
    'Contact information': Contactinformation;
  };
}
type General = {
  '1.UCI': '';
  '2.I want service in': string;
  '3.Visa requested': string;
};
type PersonalDetails = {
  '1.1.Family name': string;
  '1.2.Given name': string;
  '3.Sex': string;
  '4.Date of birth': string;
  '5.1.Place of birth': string;
  '5.2.Country or Territory': string;
  '6.Citizenship': string;
  '7.1.Country or Territory': string;
  '7.2.Status': string;
  '7.3.From': string;
  '7.4.To': string;
  '9.Country or Terrirtory where applying': string;
  '10.1.Your current marital status': string;
  '10.2.Provide the date on this status': string;
  '10.3.Family name of spouse': string;
  '10.4.Given name of spouse': string;
  '11.Have you previously been married or in a common-law relationship?': string;
};
type Passport = {
  '1.Passport number': string;
  '2.Country or territory of issue': string;
  '3. Issue date': string;
  '4. Expiary date': string;
  '5. For this trip, will you use...': string;
  '6. For this trip, will you use...': string;
};
type Languages = {
  '1.a.Native language': string;
  '1.b.Are you able to communicate in English and/or French?': string;
  '1.c.In which language are you most at ease?': string;
  '1.d.Have you taken a test ...': string;
};
type Contactinformation = {
  '1.1. Current mailing address. Apt/unit number': string;
  '1.2. Street no.': string;
  '1.3. Street name': string;
  '1.4. City/Town': string;
  '1.5. Country or Territory': string;
  '1.6.Province/State': string;
  '1.7.Postal Code': string;
  '2.Residential adress': string;
  '3.Telephone no.': string;
  '4.E-mail address': string;
};
type Card = {
  '1. Are you a lawful permanent resident...': string;
};
type NationalIdentityDocument = {
  '1.Do you have a national identity document?': string;
  '2.Document number': string;
  '3.Country or territory of issue': string;
  '4. Issue date': string;
  '5.Expiry date': string;
};

export async function enterpriseSearch(body: EnterpriseSearchType | string) {
  try {
    const { data } = await request.post<any>('/enterprise', body);
    return data;
  } catch (error) {
    return '400';
  }
}
