import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from 'src/modules/General/containers/Layout';
import DefaultErrorPage from 'src/pages/Errors';
import Queries from 'src/pages/Queries';
import Emails from 'src/pages/Emails';
import Files from 'src/pages/Files';
import Login from 'src/pages/Login';
import Register from 'src/pages/Register';
import RequireAuth from 'src/modules/General/components/RequireAuth';
import ForgetPassword from 'src/pages/ForgetPassword';
import ResetPassword from 'src/pages/ResetPassword';
import EnterpriseSearch from 'src/pages/EnterpriseSearch';
import Notifications from 'src/pages/Notifications';
import Notes from 'src/pages/Notes';
import Privacy from 'src/pages/Privacy';
import Terms from 'src/pages/Terms';
import Signup from 'src/pages/Signup';
import Instruction from 'src/pages/Instruction';
import { NotificationsProvider } from '../contexts/notifications.context';
import DocumentsSection from 'src/pages/Documents/Core';
import Settings from 'src/pages/Settings';
import DirectSubscription from 'src/pages/direct-subscription';
import SuccessPage from 'src/pages/Settings/success';
import FaildPage from 'src/pages/Settings/faild';
import ResultRegister from 'src/pages/Signup/Result';
import SubscriptionPlans from 'src/pages/SubscriptionPlans';

export const Router = () => {
  const accessToken = localStorage.getItem('access_token');
  const blueprint: RouteObject[] = [
    {
      element: <Layout />,
      errorElement: <DefaultErrorPage />,
      children: [
        {
          index: true,
          element: accessToken !== null ? <Navigate to="/documents" replace /> : <Navigate to="/user-login" replace />,
        },
        {
          path: '/search',
          element: (
            <RequireAuth>
              <Queries />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/my-emails',
          element: (
            <RequireAuth>
              <Emails />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/my-files',
          element: (
            <RequireAuth>
              <Files />
            </RequireAuth>
          ),
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/auto-Gen',
          element: (
            <RequireAuth>
              <EnterpriseSearch />
            </RequireAuth>
          ),
        },
        {
          path: '/my-notifications',
          element: (
            <RequireAuth>
              <NotificationsProvider>
                <Notifications />
              </NotificationsProvider>
            </RequireAuth>
          ),
        },
        {
          path: '/note',
          element: (
            <RequireAuth>
              <Notes />
            </RequireAuth>
          ),
        },
        {
          path: '/documents',
          element: (
            <RequireAuth>
              <DocumentsSection />
            </RequireAuth>
          ),
        },
        {
          path: '/settings',
          element: (
            <RequireAuth>
              <Settings />
            </RequireAuth>
          ),
        },
        {
          path: '/user-login',
          element: <Login />,
        },
        {
          path: '/user-sign-up',
          element: <Signup />,
        },
        {
          path: '/user-forget-password',
          element: <ForgetPassword />,
        },
        {
          path: '/user-reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/user-register',
          element: <Register />,
        },
        {
          path: '/privacy-policy',
          element: <Privacy />,
        },
        {
          path: '/instuction-add-email',
          element: <Instruction />,
        },
        {
          path: '/terms',
          element: <Terms />,
        },

        {
          path: '/direct-subscription',
          element: <DirectSubscription />,
        },
        {
          path: '/plans',
          element: <SubscriptionPlans />,
        },
        {
          path: '/success',
          element: <SuccessPage />,
        },
        {
          path: '/cancel',
          element: <FaildPage />,
        },
        {
          path: '/user-registered',
          element: <ResultRegister />,
        },
      ],
    },
  ];

  const routes = createBrowserRouter(blueprint);

  return <RouterProvider router={routes} />;
};
