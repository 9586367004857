import { useState, useEffect } from 'react';
import backgroundAuth1 from 'src/assets/images/Auth/1.png';
import backgroundAuth2 from 'src/assets/images/Auth/2.png';
import backgroundAuth3 from 'src/assets/images/Auth/3.png';
import backgroundAuth4 from 'src/assets/images/Auth/4.png';

const Right: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeProp, setFadeProp] = useState('fade-in');

  const images = [backgroundAuth1, backgroundAuth2, backgroundAuth3, backgroundAuth4];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeProp('fade-out');

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeProp('fade-in');
      }, 300);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div
      className={`relative w-full h-full bg-no-repeat bg-cover rounded-l-80 flex items-center justify-center px-16 bg-center ${fadeProp}`}
      style={{
        backgroundImage: `url(${images[currentImageIndex]})`,
      }}>
      {/* Overlay gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#00265f] to-transparent opacity-80 z-10 rounded-l-80"></div>

      {/* Text container */}
      <div className="absolute top-8 left-8 z-20 text-white">
        {/* Horizontal line */}

        {/* Text */}
        <h1 className="text-4xl font-bold leading-12.5">
          Suprecharge <br />
          Your Efficiency
          <br />
          with DeedDex:
        </h1>
        <p className="mt-4 mb-4 text-lg font-semibold leading-8">
          Instantly Access and Analyze
          <br />
          Your Document Pool
        </p>
        <div className="h-2 bg-orange-500 w-20 mb-4"></div>
      </div>
    </div>
  );
};

export default Right;
