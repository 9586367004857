import Header from 'src/modules/General/containers/Layout/Header';
import UserIcon from 'src/assets/images/Settings/user.svg';
import InfoIcon from 'src/assets/images/Settings/info-circle.svg';
import { ChangeEvent, DetailedHTMLProps, SelectHTMLAttributes, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Divider } from '@mui/material';
import Profile from './Profile';
import TablePlans from './Table';
import CurrentPlan from './CurrentPlan';
import PlanCard from './PlanCard';
import { AppProvider, useAppContext } from 'src/core/contexts/_app.context';
import SettingsSubscriptionService from './index.services';
import Loading from 'src/modules/General/components/Loading';
import { Amount, purchase, PurchaseDataType } from 'src/modules/Settings/api';

const Settings = () => {
  const {
    ActiveTrialSubscription,
    isError,
    loading,
    message,
    isSuccess,
    isErrorList,
    loadingList,
    subscriptionsList,
    messageList,
    plans,
  } = SettingsSubscriptionService();
  const { state } = useAppContext();
  const { subscription } = state || {};
  const [userInfo, setUserInfo] = useState<any>();
  const [value, setValue] = useState('2');
  const [compareTab, setCompareTab] = useState('individual');
  const [selectedPlan, setSelectedPlan] = useState<string | null>('Free Trial');
  const [subscriptionId, setSubscriptionId] = useState<number>(0);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('month');
  const [amountPlan, setAmountPlan] = useState<Amount[]>([]);
  const [price, setPrice] = useState(0);
  const [step, setStep] = useState<number>(1);
  const [questionAnswering, setQuestionAnswering] = useState<number>(2000);
  const [users, setUsers] = useState<number>(2000);
  const [pages, setPages] = useState<number>(1000);
  const [emails, setEmails] = useState<number>(1000);

  const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const changeStep = (step: number) => {
    setStep(step);
  };
  const changePeriod = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPalnType = event.currentTarget.value;
    setSelectedPeriod(selectedPalnType);
    if (selectedPalnType === 'month') {
      setPrice(amountPlan[1].amount);
    } else {
      setPrice(amountPlan[0].amount);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleCompareTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setCompareTab(newTab);
  };

  useEffect(() => {
    if (localStorage?.getItem('userData')) {
      setUserInfo(JSON.parse(localStorage?.getItem('userData') ?? ''));
    }
  }, []);
  const handlePlanSelection = (plan: string, subId: number) => {
    setSelectedPlan(plan);
    setSubscriptionId(subId);
    const planSelected = plans.find((plan) => plan.subscriptionId === subId) || null;
    setAmountPlan(planSelected?.amount ?? []);
    setPrice(planSelected?.amount[1].amount ?? 0);
    setSelectedPeriod('month');
  };
  const getUrl = async () => {
    const body: PurchaseDataType = {
      subscription_id: subscriptionId,
      period: selectedPeriod,
    };
    const url = await purchase(body);
    if (typeof url !== 'string') {
      window.open(url.url, '_blank');
    }
  };
  return (
    <AppProvider>
      <Header />
      <div className="w-sideBar h-full max-lg:h-calc-120 max-lg:w-full py-8 px-6">
        <div className="w-full h-full bg-white rounded-xl flex flex-col justify-start items-start gap-6 px-8 py-6">
          <div className="w-full h-32 flex justify-start items-center gap-4">
            <div className="w-20 h-20 p-2 bg-[#dfdfdf] rounded-full flex justify-center items-center gap-2.5">
              <img src={UserIcon} alt="userIcon" className="w-10 h-10" />
            </div>
            <div className="flex flex-col justify-start items-start gap-2">
              <div className="text-[#0c0c0c] text-lg font-medium">
                {userInfo?.firstName + ' ' + userInfo?.lastName || 'User'}
              </div>
              <div className="text-neutral-700 text-base font-medium">{userInfo?.email}</div>
            </div>
          </div>
          <div className="w-full h-calc-128 overflow-y-hidden">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} textColor="primary" indicatorColor="primary">
                  <Tab label="Profile" value="1" />
                  <Tab label="Billing" value="2" />
                </TabList>
              </Box>
              <div className="w-full h-calc-50 overflow-auto pt-6">
                <TabPanel value="1">
                  <Profile
                    email={userInfo?.email}
                    firstName={userInfo?.firstName}
                    lastName={userInfo?.lastName}
                    username={userInfo?.username}
                  />
                </TabPanel>
                <TabPanel value="2">
                  {subscription.subscription_status !== 'expired_or_inactive' && step === 1 && (
                    <CurrentPlan
                      interactive={subscription?.quotas?.interactive}
                      doc_length={subscription?.quotas?.doc_length}
                      document_retrieval={subscription?.quotas?.document_retrieval}
                      email_accounts={subscription?.quotas?.email_accounts}
                      total_free_pages={subscription?.quotas?.total_free_pages}
                      users={subscription?.quotas?.users}
                      num_primary_email_msgs={subscription?.quotas?.num_primary_email_msgs}
                      subscription_period_end={subscription?.subscription_period_end}
                    />
                  )}
                  {subscription.subscription_status === 'expired_or_inactive' && (
                    <div className="w-ful p-3 bg-red-300 rounded shadow">
                      <p className="text-center text-lg text-red-700 font-bold">Please renew or subscribe to a plan!</p>
                    </div>
                  )}

                  <Divider />
                  <div className="w-full py-6 flex flex-col justify-start items-center gap-8">
                    {step === 1 && (
                      <>
                        <p className="text-center text-[#0c0c0c] text-xl font-bold leading-snug md:text-2xl">
                          Compare our plans and find yours
                        </p>
                        {/* <TabContext value={compareTab}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleCompareTabChange} textColor="primary" indicatorColor="primary">
                              <Tab label="Individual Plans" value="individual" />
                              <Tab label="Enterprise Plans" value="enterprise" />
                            </TabList>
                          </Box> */}
                        {/* <TabPanel value="individual"> */}
                        <div className="w-full flex flex-wrap items-start justify-center gap-4 mb-5">
                          {loadingList ? (
                            <Loading variant="circle" size={60} color="primary" />
                          ) : (
                            <>
                              <PlanCard
                                name="Free Trial"
                                monthlyPrice="Free"
                                yearlyPrice="Free"
                                description="Try free plan for 14 days."
                                isSelected={selectedPlan === 'Free Trial'}
                                onSelect={() => handlePlanSelection('Free Trial', 0)}
                              />
                              {plans.map((plan, index) => (
                                <PlanCard
                                  key={index}
                                  isSelected={selectedPlan === plan.name}
                                  name={plan.name}
                                  monthlyPrice={'$' + plan.amount[1].amount}
                                  yearlyPrice={'$' + plan.amount[0].amount}
                                  onSelect={() => handlePlanSelection(plan.name, plan.subscriptionId)}
                                />
                              ))}
                            </>
                          )}
                        </div>

                        <div className="w-full pb-4">
                          <div className="flex flex-col">
                            {isError && !loading && (
                              <p className="p-4 bg-red-400 rounded mb-2 text-center text-base text-white w-full flex justify-center">
                                {message}
                              </p>
                            )}
                            {isSuccess && !loading && (
                              <p className="p-4 bg-green-400 rounded mb-2 text-center text-base text-white w-full flex justify-center">
                                {message}
                              </p>
                            )}
                            <div className="flex flex-row justify-between items-center gap-3 mb-3">
                              <div className="text-[#0066ff] text-xs font-bold">Overview</div>
                              {subscription.subscription_status === 'expired_or_inactive' &&
                                selectedPlan === 'Free Trial' && (
                                  <button
                                    onClick={ActiveTrialSubscription}
                                    className="h-12 px-6 py-3 bg-[#0066ff] rounded-xl text-white flex items-center justify-center gap-2">
                                    {loading && <Loading variant="circle" size={24} color="inherit" />}
                                    Active Trial
                                  </button>
                                )}
                              {selectedPlan !== 'Free Trial' && (
                                <div className="flex flex-row items-center gap-2">
                                  <label htmlFor="perod" className="font-semibold">
                                    Please specify the time period of the selected plan:{' '}
                                  </label>
                                  <select
                                    value={selectedPeriod}
                                    onChange={changePeriod}
                                    className="p-2 w-36 border border-orange-400 rounded">
                                    <option value="month">Monthly</option>
                                    <option value="year">Yearly</option>
                                  </select>

                                  <button
                                    onClick={() => changeStep(2)}
                                    className="h-12 px-6 py-3 bg-[#0066ff] rounded-xl text-white flex items-center justify-center gap-2">
                                    {loading && <Loading variant="circle" size={24} color="inherit" />}
                                    Next
                                  </button>
                                </div>
                              )}
                            </div>

                            <div className="mb-4">
                              {!loadingList ? (
                                !isErrorList ? (
                                  <TablePlans subscriptions={subscriptionsList} />
                                ) : (
                                  <p>{messageList}</p>
                                )
                              ) : (
                                // <Loading variant="circle" size={60} color="primary" />
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* </TabPanel> */}
                        {/* <TabPanel value="enterprise">
                            <p className="text-center text-gray-700">Explore our plans designed for enterprises.</p>
                          </TabPanel>
                        </TabContext> */}
                      </>
                    )}
                    {step === 2 && (
                      <div className="w-full h-full flex flex-col gap-4">
                        <button
                          className="h-11 w-fit px-8 py-3 bg-white rounded-xl shadow border border-[#0066ff] justify-center items-center gap-2 inline-flex text-[#0066ff] text-base font-medium font-['Inter'] capitalize leading-tight"
                          onClick={() => changeStep(1)}>
                          Back
                        </button>
                        <div className="flex flex-col items-start gap-4 mb-4">
                          <div className="flex items-end gap-1">
                            <div className="text-[#0c0c0c] text-lg font-medium">Selected Plan:</div>
                            <div className="text-[#202020] text-base font-medium">
                              {selectedPlan} - {selectedPeriod}
                            </div>
                          </div>
                        </div>
                        <Divider />
                        {/* <div className="h-10 px-3 py-2 bg-[#ffe4bd] rounded-xl justify-start items-center gap-2 inline-flex">
                          <div className="w-6 h-6 justify-center items-center flex">
                            <div className="w-6 h-6 relative flex-col justify-start items-start flex" />
                          </div>
                          <div className="text-[#de710d] text-sm font-normal font-['Inter'] leading-[16.80px]">
                            Adding more items than the specified limit of the selected plan will incur a higher cost.
                          </div>
                        </div>
                        <div className="h-[63px] justify-between items-start inline-flex">
                          <div className="justify-start items-start gap-1.5 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-[#0c0c0c] text-sm font-normal font-['Inter'] leading-[16.80px]">
                                Interactive
                              </div>
                              <div className="justify-center items-center gap-1.5 inline-flex">
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">+</div>
                                </div>
                                <div className="w-[130px] h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#ededed] justify-start items-center gap-2 flex">
                                  <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-2 flex">
                                    <div className="grow shrink basis-0 text-center text-[#0c0c0c] text-base font-normal font-['Inter'] leading-tight">
                                      100
                                    </div>
                                  </div>
                                </div>
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-1.5 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-[#0c0c0c] text-sm font-normal font-['Inter'] leading-[16.80px]">
                                Retrieval
                              </div>
                              <div className="justify-center items-center gap-1.5 inline-flex">
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">+</div>
                                </div>
                                <div className="w-[130px] h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#ededed] justify-start items-center gap-2 flex">
                                  <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-2 flex">
                                    <div className="grow shrink basis-0 text-center text-[#0c0c0c] text-base font-normal font-['Inter'] leading-tight">
                                      100
                                    </div>
                                  </div>
                                </div>
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-1.5 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-[#0c0c0c] text-sm font-normal font-['Inter'] leading-[16.80px]">
                                Users
                              </div>
                              <div className="justify-center items-center gap-1.5 inline-flex">
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">+</div>
                                </div>
                                <div className="w-[130px] h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#ededed] justify-start items-center gap-2 flex">
                                  <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-2 flex">
                                    <div className="grow shrink basis-0 text-center text-[#0c0c0c] text-base font-normal font-['Inter'] leading-tight">
                                      2000
                                    </div>
                                  </div>
                                </div>
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-1.5 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-[#0c0c0c] text-sm font-normal font-['Inter'] leading-[16.80px]">
                                Email Accounts
                              </div>
                              <div className="justify-center items-center gap-1.5 inline-flex">
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">+</div>
                                </div>
                                <div className="w-[130px] h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#ededed] justify-start items-center gap-2 flex">
                                  <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-2 flex">
                                    <div className="grow shrink basis-0 text-center text-[#0c0c0c] text-base font-normal font-['Inter'] leading-tight">
                                      200
                                    </div>
                                  </div>
                                </div>
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-1.5 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-[#0c0c0c] text-center text-sm font-normal font-['Inter'] leading-[16.80px]">
                                Pages
                              </div>
                              <div className="justify-center items-center gap-1.5 inline-flex">
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-start items-start flex">+</div>
                                </div>
                                <div className="w-[130px] h-10 px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#ededed] justify-start items-center gap-2 flex">
                                  <div className="grow shrink basis-0 h-[19px] justify-center items-center gap-2 flex">
                                    <div className="grow shrink basis-0 text-center text-[#0c0c0c] text-base font-normal font-['Inter'] leading-tight">
                                      100000
                                    </div>
                                  </div>
                                </div>
                                <div className="w-6 h-6 shadow justify-center items-center flex">
                                  <div className="w-6 h-6 relative flex-col justify-center items-center flex">-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Divider /> */}
                        {/* <div className="h-[52px] flex-col justify-start items-start gap-2 inline-flex">
                          <div className="justify-start items-start gap-4 inline-flex">
                            <div className="justify-start items-end gap-1 flex">
                              <div className="text-[#0c0c0c] text-lg font-medium font-['Inter']">Price:</div>
                              <div className="text-[#202020] text-base font-medium font-['Inter']">10 $</div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-4 inline-flex">
                            <div className="justify-start items-end gap-1 flex">
                              <div className="text-[#0c0c0c] text-lg font-medium font-['Inter']">Added Fee:</div>
                              <div className="text-[#202020] text-base font-medium font-['Inter']">10 $</div>
                            </div>
                          </div>
                        </div> */}
                        {/* <Divider /> */}
                        <div className="h-[22px] justify-start items-end gap-1 inline-flex">
                          <div className="text-[#0c0c0c] text-lg font-medium font-['Inter']">Total Price:</div>
                          <div className="text-[#202020] text-base font-medium font-['Inter']">{price} $</div>
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            onClick={getUrl}
                            className="w-[121px] h-12 px-4 py-3 bg-[#0066ff] rounded-xl shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium font-['Inter'] capitalize leading-tight">
                            Purchase
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </div>
            </TabContext>
          </div>
        </div>
      </div>
    </AppProvider>
  );
};

export default Settings;
