import React from 'react';
import CheckIcon from 'src/assets/images/Queries/check.svg';

interface PlanCardProps {
  name: string;
  monthlyPrice: string;
  yearlyPrice: string;
  isSelected: boolean;
  description?: string;
  onSelect: () => void;
}

const PlanCard: React.FC<PlanCardProps> = ({ name, monthlyPrice, yearlyPrice, isSelected, description, onSelect }) => {
  return (
    <div
      onClick={onSelect}
      className={`w-60 h-auto p-6 relative rounded cursor-pointer transition-all duration-300 ease-in-out transform ${
        isSelected
          ? 'border-orange-500 border-2 scale-105 shadow-lg'
          : 'border-gray-400 border hover:scale-105 hover:shadow-md'
      }`}>
      {isSelected && (
        <div className="absolute top-2 right-2 bg-orange-500 text-white rounded-full p-1">
          <img src={CheckIcon} alt="checkIcon" className="w-3 h-3" />
        </div>
      )}
      <div className="h-[54px] w-full px-6 pb-1 mb-2 border-b border-[#ededed] justify-center items-center inline-flex">
        <p className="text-[#0c0c0c] text-lg font-bold font-['Inter'] leading">{name}</p>
      </div>
      <div>
        <p className={`${description ? 'text-black' : 'text-white'}`}>{description ?? 'description'}</p>
      </div>
      <div className="h-[43px] justify-start items-end gap-1 inline-flex">
        <div className="text-[#0f1728] text-2xl font-bold font-['Inter'] leading-[43.20px]">{monthlyPrice}</div>
        <div className="pb-2 justify-start items-start flex">
          <div className="text-neutral-700 text-base font-medium font-['Inter']">per month</div>
        </div>
      </div>
      <div className="h-[43px] justify-start items-end gap-1 inline-flex">
        <div className="text-[#0f1728] text-2xl font-bold font-['Inter'] leading-[43.20px]">{yearlyPrice}</div>
        <div className="pb-2 justify-start items-start flex">
          <div className="text-neutral-700 text-base font-medium font-['Inter']">per year</div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
