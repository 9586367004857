import { useNotificationsContext } from 'src/core/contexts/notifications.context';
import { feedbackNotification, getDetailsEmail, NotificationData } from '../../api';
import { useEffect, useState } from 'react';
import { downloadFile, downloadRecentFile } from 'src/modules/Queries/api';
import { EmailDataType } from '../NotificationEmailDialog/index.types';

const notificationDialogService = () => {
  const { dispatch } = useNotificationsContext();
  const [fileNameDownload, setFileNameDownload] = useState<string>('');
  const [fileIdDownload, setFileIdDownload] = useState<number>(0);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<number | null>(null);
  const [blob, setBlob] = useState<Blob>(new Blob());
  const [loadingDownload, setLoadingdownload] = useState<boolean>(false);
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [emailInfo, setEmailInfo] = useState<EmailDataType>();
  const seenNotification = async (datetimes: string, notifications: NotificationData[]) => {
    const body = {
      datetimes: [datetimes, datetimes],
      method: 0,
    };
    const seen = await feedbackNotification(body);

    const data = [...notifications];
    for (let i = 0; i < data.length; i++) {
      if (data[i].DateTime === datetimes) {
        data[i].Seen = true;
        dispatch({ type: 'notifications', value: data });
        break;
      }
    }
  };

  const resolveNotification = async (datetimes: string, notifications: NotificationData[]) => {
    const body = {
      datetimes: [datetimes, datetimes],
      method: 1,
    };
    const feedBack = await feedbackNotification(body);

    const data = [...notifications];

    for (let i = 0; i < data.length; i++) {
      if (data[i].DateTime === datetimes) {
        data[i].Resolved = true;
        dispatch({ type: 'notifications', value: data });
        break;
      }
    }
  };
  const onPreviewDialog = (documentName: string, documentId: number) => {
    setOpenPreview(true);
    downloadDataFile(documentName, documentId);
    setFileIdDownload(documentId);
    setFileNameDownload(documentName);
  };
  const openEmailDialog = (emailId: number) => {
    setLoadingEmail(true);
    goToEmail(emailId);
  };
  const closeEmailDialog = () => {
    setOpenEmail(false);
    setLoadingEmail(false);
    setEmailId(null);
  };
  const closeDialog = () => {
    setBlob(new Blob());
    setPdfData(new Uint8Array());
    setOpenPreview(false);
  };
  const downloadDataFile = (fileName: string, documentId: number) => {
    setLoadingdownload(true);
    downloadRecentFile(fileName, documentId).then((result) => {
      setLoadingdownload(false);
      if (typeof result === 'object') {
        setBlob(result);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            const uint8Array = new Uint8Array(reader.result);
            if (uint8Array.length === 0) {
              return;
            }
            setPdfData(new Uint8Array(uint8Array));
          }
        };
        setLoadingdownload(false);
        reader.readAsArrayBuffer(result);
      }
    });
  };

  const goToEmail = async (id: number) => {
    try {
      const response = await getDetailsEmail(id);
      setLoadingEmail(false);
      if (response?.error_code === 0) {
        setOpenEmail(true);

        setEmailInfo(response?.data);
      } else {
        setErrorMessage(response?.message ?? 'Error');
      }
    } catch (error) {
      setLoadingEmail(false);
      console.log(error);
    }
  };
  const onRetry = () => {
    if (fileNameDownload && fileIdDownload) {
      downloadDataFile(fileNameDownload, fileIdDownload);
    }
  };

  return {
    openPreviewModal: openPreview,
    openEmailModal: openEmail,
    closeDialog,
    closeEmailDialog,
    seenNotification,
    resolveNotification,
    onPreviewDialog,
    openEmailDialog,
    pdfData,
    onRetry,
    errorMessage,
    setErrorMessage,
    loadingDownload,
    loadingEmail,
    emailInfo,
  };
};

export default notificationDialogService;
