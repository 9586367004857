import EmailsList from 'src/modules/Emails/containers/EmailsList';
import EmailAuthModal from 'src/modules/Emails/containers/EmailAuthModal';
import Snackbar from 'src/modules/General/components/Snackbar';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import useEmailsLeftColumn from './index.services';
import Loading from 'src/modules/General/components/Loading';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';
import { AddEmailsDataType } from 'src/modules/Emails/api';
import { SnackbarProps } from '../HeaderMenu';
import { useTranslation } from 'react-i18next';
import EmailChangePasswordModal from 'src/modules/Emails/containers/EmailChangePassword';
import { ChangeEmailsDataType } from 'src/modules/Emails/containers/EmailChangePassword/index.types';
import UpgradeDialog from 'src/modules/General/components/DialogUpgrade';
import { useEffect, useState } from 'react';
import { initGoogleAPI, signInWithGoogle } from 'src/modules/Emails/api/googleAuth';
import { gapi } from 'gapi-script';
interface RightEmailProps {
  emailsList: EmailProps[];
  isLoading: boolean;
  openEmailModal: boolean;
  onRemoveEmailClick: (email: string) => void;
  onRemoveEmail: () => Promise<void>;
  updateEmailsList: (call: boolean, processCal: boolean) => Promise<void>;
  onAddEmail: (formData: AddEmailsDataType) => Promise<void>;
  setOpenEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  openConfirmationModal: {
    name: 'remove' | 'deactivate';
    open: boolean;
  };
  closeConfirmationModal: () => void;
  showSnackbar: SnackbarProps;
  handleCloseSnackbar: () => void;
  onDeactivateEmail: () => Promise<void>;
  onActiveOrDeactiveEmail: (isActive: boolean, email: string) => Promise<void>;
  isRemoveAction: boolean;
  messageConfirmationModal: string;
  isOpenConfirmationModal: boolean;
  setOpenPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  openChangePasswordModal: boolean;
  changePasswordEmail: (formData: ChangeEmailsDataType) => void;
  handleOpenCahngePasswordModal: (address: string, host: string) => void;
  handleCloseChangePasswordEmailModal: () => void;
  handleSignInByGmailService: () => void;
  emailAddress: string;
  host: string;
}
const RightColumn = ({
  emailsList,
  isLoading,
  showSnackbar,
  onAddEmail,
  onRemoveEmail,
  onRemoveEmailClick,
  updateEmailsList,
  handleCloseSnackbar,
  openEmailModal,
  setOpenEmailModal,
  openConfirmationModal,
  closeConfirmationModal,
  onActiveOrDeactiveEmail,
  onDeactivateEmail,
  isRemoveAction,
  messageConfirmationModal,
  isOpenConfirmationModal,
  openChangePasswordModal,
  setOpenPasswordModal,
  changePasswordEmail,
  handleCloseChangePasswordEmailModal,
  handleOpenCahngePasswordModal,
  emailAddress,
  host,
  handleSignInByGmailService,
}: RightEmailProps) => {
  const { t } = useTranslation();
  const {
    onEmailClick,
    setIsLoading,
    openModalAddEmail,
    handleClose,
    handleGoToSettings,
    isDialogOpen,
    messeageSubscription,
  } = useEmailsLeftColumn();

  // const [authCode, setAuthCode] = useState<string | null>(null);
  // const [accessToken, setAccessToken] = useState<string | null>(null);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const code = urlParams.get('code');
  //   console.log(code);
  //   if (code) {
  //     // setAuthCode(code);
  //     // getAccessToken(code);
  //   }
  // }, []);

  // const getAccessToken = async (authorizationCode: string) => {
  //   const response = await fetch('https://oauth2.googleapis.com/token', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: new URLSearchParams({
  //       code: authorizationCode,
  //       client_id: '973087283056-4t32g34jjn5ar6nv2vha9p2i4l9iq2hr.apps.googleusercontent.com',
  //       client_secret: 'YOUR_CLIENT_SECRET',
  //       redirect_uri: 'http://localhost:3000/my-emails',
  //       grant_type: 'authorization_code',
  //     }),
  //   });

  //   const data = await response.json();
  //   setAccessToken(data.access_token);
  //   console.log('Access Token:', data.access_token);
  // };

  // const handleSignIn = () => {
  //   const clientId = '973087283056-4t32g34jjn5ar6nv2vha9p2i4l9iq2hr.apps.googleusercontent.com';
  //   const redirectUri = 'http://localhost:3000/my-emails';
  //   const scope =
  //     'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.email openid';

  //   const url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=${encodeURIComponent(scope)}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}&access_type=offline&prompt=consent&service=lso&o2v=2&ddm=1&flowName=GeneralOAuthFlow`;

  //   window.location.href = url;
  // };

  return (
    <>
      <div className="w-full h-full py-8 px-3 bg-white border-l border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
        <h3 className="text-neutral-950 text-xl font-bold">{t('My Email Accounts')}</h3>
        {isLoading ? (
          <div className="w-full h-full flex items-start justify-center">
            <Loading size={40} variant="circle" />
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-2 items-center justify-between flex-row gap-x-4">
              <button
                onClick={() => {
                  if (!openModalAddEmail()) {
                    setOpenEmailModal(true);
                    setIsLoading(false);
                  }
                }}
                className="h-11 px-1 py-3 bg-blue-600 rounded-xl shadow justify-center items-center inline-flex text-white text-sm font-medium leading-tigh">
                {t('Add Email Account')}
              </button>
              <button
                onClick={() => updateEmailsList(false, true)}
                className="h-11 px-1 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center inline-flex text-blue-600 text-sm font-medium leading-tight">
                {t('Sync Manually')}
              </button>
            </div>
            <EmailsList
              emails={emailsList}
              onEmailClick={onEmailClick}
              onRemoveEmail={onRemoveEmailClick}
              onActiveOrDeactiveEmail={onActiveOrDeactiveEmail}
              onChangePassword={handleOpenCahngePasswordModal}
            />
            {/* <button onClick={handleSignInByGmailService}>Sign in with Google</button> */}
          </>
        )}
      </div>
      <EmailAuthModal
        handleSignInByGmail={handleSignInByGmailService}
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmitAddEmail={onAddEmail}
        isLoading={isLoading}
      />
      <EmailChangePasswordModal
        open={openChangePasswordModal}
        onClose={handleCloseChangePasswordEmailModal}
        onSubmitChangePasswordEmail={changePasswordEmail}
        isLoading={isLoading}
        address={emailAddress}
        host={host}
      />
      {showSnackbar.display && (
        <Snackbar
          color={showSnackbar.color}
          open={showSnackbar.display}
          onClose={handleCloseSnackbar}
          message={showSnackbar?.message}
        />
      )}
      <UpgradeDialog
        onClose={handleClose}
        onUpgrade={handleGoToSettings}
        message={messeageSubscription}
        open={isDialogOpen}
      />
      <ConfirmationModal
        open={isOpenConfirmationModal}
        onClose={closeConfirmationModal}
        text={messageConfirmationModal}
        buttons={[
          {
            children: isRemoveAction ? t('Remove') : t('Deactivate'),
            variant: 'contained',
            color: 'error',
            onClick: isRemoveAction ? onRemoveEmail : onDeactivateEmail,
            endIcon: isLoading && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeConfirmationModal,
          },
        ]}
      />
    </>
  );
};

export default RightColumn;
