import React, { useState } from 'react';

import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Link, Divider } from '@mui/material';
import MenuICON from 'src/assets/images/AppBar/menu.png';
import Logo from 'src/modules/General/components/Logo';

const ResponsiveAppBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const links = [
    { label: 'Download Android Version', href: 'https://nexadeeds.com/download/' },
    { label: 'Privacy Policy', href: '/privacy-policy' },
    { label: 'Subscriptions', href: '/plans' },
    { label: 'Contact Us', href: 'https://nexadeeds.com/contact/' },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(90deg, #007bff, #0056b3)',
        paddingX: { xs: 2, md: 2 },
        height: '64px',
        minHeight: '64px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}>
      <Toolbar sx={{ minHeight: '64px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', background: 'white', padding: '4px', borderRadius: '8px' }}>
          <Logo />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            gap: 4,
            alignItems: 'center',
          }}>
          {links.map((link) => (
            <Link
              key={link.label}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontWeight: 500,
                fontSize: '1rem',
                color: '#fff',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                transition: 'color 0.3s ease, transform 0.2s ease',
                '&:hover': { color: 'orange', transform: 'scale(1.1)' },
              }}>
              {link.label}
            </Link>
          ))}
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'end',
            alignItems: 'center',
            flexGrow: 1,
            gap: 4,
          }}>
          <IconButton edge="start" onClick={handleMenuOpen}>
            <img src={MenuICON} alt="menu" style={{ width: '28px', height: '28px' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '& .MuiPaper-root': {
                background: 'linear-gradient(90deg, #007bff, #0056b3)',
                color: '#fff',
              },
            }}>
            {links.map((link, index) => (
              <Box key={link.label}>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      color: '#fff',
                      textDecoration: 'none',
                      transition: 'color 0.3s ease',
                      '&:hover': { color: '#ffd700' },
                    }}>
                    {link.label}
                  </Link>
                </MenuItem>
                {index < links.length - 1 && (
                  <Divider
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      margin: '4px 0',
                    }}
                  />
                )}
              </Box>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;
