import React from 'react';
import ResponsiveAppBar from '../AppBar';
import CheckIcon from 'src/assets/images/Plans/check.png';
import FaildIcon from 'src/assets/images/Plans/remove.png';
import Logo from 'src/modules/General/components/Logo';
import NotificationIcon from 'src/assets/images/Plans/bell.png';
import LockIcon from 'src/assets/images/Plans/3d-lock.png';
import RetrievalIcon from 'src/assets/images/Plans/document.png';
import InteractiveIcon from 'src/assets/images/Plans/chatbot.png';
import UploadIcon from 'src/assets/images/Plans/upload.png';
import FreePagesIcon from 'src/assets/images/Plans/pages.png';
import UsersIcon from 'src/assets/images/Plans/group.png';
import EmailAccountsIcin from 'src/assets/images/Plans/mail-inbox.png';
import MessageIcon from 'src/assets/images/Plans/open-email.png';
import { Divider } from '@mui/material';

interface Plan {
  name: string;
  description: string;
  notification: boolean;
  multiLevelAccess: boolean;
  clientCloud: boolean;
  twoFactorAuth: boolean;
  interactive: string;
  documentRetrieval: string;
  users: number;
  emailAccounts: number;
  emailMessages: string;
  uploadSize: number;
  freePages: string;
  extraPages: string;
  priceMonth: number;
  priceYear: number;
}

const plans: Plan[] = [
  {
    name: 'Free Trial',
    description: 'Try free plan for 14 days.',
    notification: false,
    multiLevelAccess: false,
    clientCloud: false,
    twoFactorAuth: false,
    interactive: 'Up to 10',
    documentRetrieval: '50',
    users: 1,
    emailAccounts: 1,
    emailMessages: '10',
    uploadSize: 10,
    freePages: '10',
    extraPages: '__',
    priceMonth: 0,
    priceYear: 0,
  },
  {
    name: 'Basic',
    description: 'Perfect for small teams with essential features.',
    notification: false,
    multiLevelAccess: false,
    clientCloud: false,
    twoFactorAuth: false,
    interactive: 'Up to 40',
    documentRetrieval: '100',
    users: 1,
    emailAccounts: 1,
    emailMessages: '100',
    uploadSize: 10,
    freePages: '100',
    extraPages: '$3 per 500 extra pages',
    priceMonth: 4.99,
    priceYear: 29.99,
  },
  {
    name: 'Standard',
    description: 'Ideal for growing teams needing more storage and features.',
    notification: true,
    multiLevelAccess: false,
    clientCloud: false,
    twoFactorAuth: false,
    interactive: 'Up to 150',
    documentRetrieval: '1,000',
    users: 1,
    emailAccounts: 3,
    emailMessages: '300',
    uploadSize: 30,
    freePages: '1,000',
    extraPages: '$3 per 500 extra pages',
    priceMonth: 14.99,
    priceYear: 125.99,
  },
  {
    name: 'Premium',
    description: 'Full access to all features with priority support.',
    notification: true,
    multiLevelAccess: false,
    clientCloud: false,
    twoFactorAuth: false,
    interactive: 'Up to 300',
    documentRetrieval: '2,000',
    users: 1,
    emailAccounts: 4,
    emailMessages: '1,000',
    uploadSize: 50,
    freePages: '2,000',
    extraPages: '$3 per 500 extra pages',
    priceMonth: 29.99,
    priceYear: 287.99,
  },
  {
    name: 'Professional',
    description: 'Unlimited access and advanced features for large teams.',
    notification: true,
    multiLevelAccess: true,
    clientCloud: false,
    twoFactorAuth: true,
    interactive: 'Up to 600',
    documentRetrieval: 'Unlimited',
    users: 1,
    emailAccounts: 10,
    emailMessages: 'Unlimited',
    uploadSize: 200,
    freePages: '5,000',
    extraPages: '$3 per 500 extra pages',
    priceMonth: 49.99,
    priceYear: 479.99,
  },
];

const SubscriptionPlans: React.FC = () => {
  return (
    <div className="w-full h-full bg-gray-50 text-gray-900 flex flex-col">
      <div className="w-full h-16">
        <ResponsiveAppBar />
      </div>
      <div className="w-full h-full p-6 text-gray-700 overflow-auto">
        <h2 className="text-3xl font-bold mb-6">Choose the Right Plan After Signing Up</h2>
        <p className="text-lg mb-4">
          After signing up, you can easily explore and purchase different plans we offer. These plans allow you to
          access various features and enhance your user experience. Each plan is designed based on your needs and the
          size of your team, from free options for trial use to more advanced plans for larger teams with special
          features.
        </p>
        <p className="text-lg mb-6">
          All plans include diverse features such as cloud storage access, document retrieval, email accounts, email
          messaging capabilities, and much more. You can compare the features of each plan to find the one that suits
          you best, and take advantage of special discounts when opting for an annual subscription.
        </p>
        <h3 className="text-2xl font-semibold mb-4">Benefits of Buying Annual Plans:</h3>
        <ul className="list-disc list-inside mb-6">
          <li>Special Discounts: Annual plans can offer up to 50% off.</li>
          <li>
            Full Access to Features: All plans provide additional features such as increased document retrieval, more
            upload space, and priority support.
          </li>
          <li>Flexibility: Choose the plan that fits your needs and easily upgrade for more features.</li>
        </ul>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 overflow-auto">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg  shadow-lg hover:shadow-lg hover:shadow-orange-200 transition-shadow duration-300">
              <h3 className="text-2xl font-semibold flex flex-row items-center gap-1 text-blue-600">
                <Logo />
                {plan.name}
              </h3>
              <p className="text-sm text-orange-500 mt-2">{plan.description}</p>
              <Divider />
              <ul className="mt-4 space-y-5 text-gray-600 py-4">
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={InteractiveIcon} className="w-6" alt="" />
                  <strong>Interactive (Monthly):</strong> <span className="">{plan.interactive}</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={RetrievalIcon} className="w-6" alt="" />
                  <strong>Document Retrieval (Monthly):</strong> <span className="">{plan.documentRetrieval}</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={UsersIcon} className="w-6" alt="" />
                  <strong>Users:</strong> <span>{plan.users}</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={EmailAccountsIcin} className="w-6" alt="" />
                  <strong>Email Accounts:</strong> <span>{plan.emailAccounts}</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={MessageIcon} className="w-6" alt="" />
                  <strong>Email Messages (Monthly):</strong> <span>{plan.emailMessages}</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={UploadIcon} className="w-6" alt="" />
                  <strong>Upload Size:</strong> <span>{plan.uploadSize} MB</span>
                </li>
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={FreePagesIcon} className="w-6" alt="" />
                  <strong>Free Pages (Monthly):</strong> <span>{plan.freePages}</span>
                </li>
                {/* <li className="flex items-center">
                  <strong>Extra Pages:</strong> <span className="ml-2">{plan.extraPages}</span>
                </li> */}
                <li className="flex flex-row gap-3 items-center mb-5">
                  <img src={NotificationIcon} className="w-6" alt="" />
                  <strong>Notifications (Monthly):</strong>{' '}
                  <span>
                    {plan.notification ? (
                      <img src={CheckIcon} className="w-6" alt="Enabled" />
                    ) : (
                      <img src={FaildIcon} className="w-6" alt="Disabled" />
                    )}
                  </span>
                </li>
                <li className="flex flex-row gap-3 items-center ">
                  <img src={LockIcon} className="w-6" alt="" />
                  <strong>Two-Factor Authentication (Monthly):</strong>{' '}
                  <span>
                    {plan.twoFactorAuth ? (
                      <img src={CheckIcon} className="w-6" alt="Enabled" />
                    ) : (
                      <img src={FaildIcon} className="w-6" alt="Disabled" />
                    )}
                  </span>
                </li>
              </ul>

              <Divider />
              <div className="mt-6 space-y-2">
                <div className="text-lg font-semibold">
                  <span className="text-green-600">Monthly: </span>${plan.priceMonth.toFixed(2)}
                </div>
                {plan.name === 'Free Trial' ? (
                  <div className="text-lg font-semibold text-green-600">
                    <span className="text-green-600">Yearly: </span>${(plan.priceMonth * 12).toFixed(2)}
                  </div>
                ) : (
                  <>
                    <div className="text-lg font-semibold line-through">
                      <span className="text-red-600">Yearly: </span>${(plan.priceMonth * 12).toFixed(2)}
                    </div>
                    <div className="text-lg font-semibold text-green-600">
                      <span className="text-green-600">Discounted Yearly: </span>${plan.priceYear.toFixed(2)} (50% Off)
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
