import Chip from 'src/modules/General/components/Chip';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';
import Loading from 'src/modules/General/components/Loading';
import styles from './index.module.scss';
import cn from 'classnames';
import Input from 'src/modules/General/components/Input';
import { addImportant, formatDate, formatFileSize, getCurrentFormattedTime } from 'src/core/utilities/general';
import { useTranslation } from 'react-i18next';
import FileIcon from 'src/assets/images/Documents/file.svg';
import TrashIcon from 'src/assets/images/Documents/trash.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UploadedFiles } from 'src/pages/Documents/Upload/index.services';
import { useAppContext } from 'src/core/contexts/_app.context';

interface AddedFilesProps {
  files: UploadedFiles[];
  onDeleteAddedFile: (fileId: number) => void;
  onChangeNote: (note: string) => void;
  onConcatFiles: (checked: boolean) => void;
  onNotifNeeded: (checked: boolean) => void;
  checked?: boolean;
  onSubmitFiles: () => void;
  loading?: boolean;
  className?: string;
  actionsClassName?: string;
  showClear: boolean;
  setShowClear: Dispatch<SetStateAction<boolean>>;
  // setFiles: Dispatch<SetStateAction<UploadedFiles[]>>;
  // selectedFiles: FilesSelected[]
}

export interface FilesSelected {
  name: string;
  type: string;
  date: string;
  size: string;
  id: number;
  fileProccess: FileProcessingResponse;
}
export interface FileProcessingResponse {
  state?: string;
  status?: string;
  progress?: string;
  taskId?: string;
  error?: string;
  result?: {
    error_code: number;
    details: {
      error_message: string;
    };
    document?: string;
    document_blob_path?: string;
    time_stamp?: string;
    user_id?: number;
  };
}

const AddedFiles = ({
  files,
  // setFiles,
  showClear,
  setShowClear,
  onDeleteAddedFile,
  onChangeNote,
  onConcatFiles,
  onNotifNeeded,
  onSubmitFiles,
  // selectedFiles,
  checked,
  loading = false,
  className = '',
  actionsClassName = '',
}: AddedFilesProps) => {
  const { state, dispatch } = useAppContext();
  const { isProgress, messageAnalyz, endProccess, selectedDocuments } = state || {};
  const { t } = useTranslation();

  const clear = () => {
    // setSelectedFiles([]);
    setShowClear(false);
    dispatch({ type: 'files', value: [] });
    dispatch({
      type: 'messageAnalyz',
      value: '',
    });
    dispatch({
      type: 'selectedDocuments',
      value: [],
    });
    dispatch({
      type: 'endProccess',
      value: 'no',
    });
  };

  return (
    <div className="w-full h-full bg-white rounded-lg p-4 shadow-md overflow-y-auto ">
      <div className="w-full h-24">
        <div className="w-11/12 max-h-11 bottom-12 left-4 overflow-x-auto overflow-y-hidden flex items-center justify-start flex-row">
          <FormControlLabel
            disabled={isProgress || endProccess !== 'end'}
            control={<Checkbox disabled={files.length < 2} onChange={(e) => onConcatFiles(e.target.checked)} />}
            label={
              <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">
                {t('Group all files')}
              </span>
            }
          />
          <FormControlLabel
            disabled={isProgress || endProccess !== 'end'}
            control={
              <Checkbox
                className="border-Orange-25"
                disabled={files.length === 0}
                checked={checked}
                onChange={(e) => onNotifNeeded(e.target.checked)}
              />
            }
            label={
              files.length > 1 ? (
                <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">
                  {t('Enable notification for all files')}
                </span>
              ) : (
                <span className="text-neutral-950 text-base font-normal leading-tight max-sm:text-xs">
                  {t('Enable notification for this file')}
                </span>
              )
            }
            className="max-sm:text-xs"
          />
        </div>
        <div className={`${actionsClassName} w-full flex items-center justify-start flex-row gap-x-4 max-sm:block`}>
          <Input
            disable={isProgress || endProccess !== 'end'}
            id="note"
            placeholder={t('Add a note...')}
            onChangeInput={(e) => {
              onChangeNote(e.target.value);
            }}
            inputStyle="rounded-lg"
          />
          <button
            className="h-11 px-4 py-3 rounded-xl bg-blue-600 rounded- shadow justify-center items-center gap-2 inline-flex text-white text-base font-medium capitalize leading-tight max-sm:text-xs max-sm:w-full max-sm:mt-2"
            disabled={!selectedDocuments.length || loading || isProgress || endProccess !== 'end'}
            onClick={onSubmitFiles}>
            {loading && <Loading variant="circle" size={20} color="inherit" />}
            {t('Submit')}
          </button>
        </div>
      </div>
      <Divider />
      <div className="w-full h-calc-108 overflow-y-auto flex flex-col gap-2">
        {messageAnalyz && (
          <div className="w-full flex flex-row gap-2 p-2">
            <p className={` ${showClear ? 'w-max-calc-208' : 'w-fit'} rounded-lg bg-blue-400 shadow-lg text-white p-2`}>
              {messageAnalyz}
            </p>
            {messageAnalyz === 'Processing of all files has been completed.' && (
              <button className="w-36 p-2 h-12 rounded-lg bg-blue-600 text-white" onClick={clear}>
                Clear History
              </button>
            )}
          </div>
        )}

        <div className="w-full h-full grid grid-cols-2 gap-2 p-2 max-md:grid-cols-1">
          {selectedDocuments?.map((file, index) => (
            <div key={index} className="w-full h-fit rounded flex flex-col">
              {file.fileProccess && file.fileProccess?.state == 'SUCCESS' && file.fileProccess.result !== null && (
                <div
                  className={`w-full h-fit p-1 rounded-t ${file.fileProccess.result?.error_code === 0 ? 'bg-green-400' : 'bg-red-400'}`}>
                  {file.fileProccess.result?.error_code === 0 && (
                    <p className="text-white">
                      The file <b>{file.name}</b> has been successfully analyzed and uploaded.
                    </p>
                  )}
                  {file.fileProccess.result?.error_code !== 0 && (
                    <p className="text-white">{file.fileProccess.result?.details.error_message}</p>
                  )}
                </div>
              )}
              {file.fileProccess && (file.fileProccess?.state == 'RETRY' || file.fileProccess?.state == 'FAILURE') && (
                <div className={`w-full h-fit p-1 rounded-t bg-red-400`}>
                  {file.fileProccess.state === 'FAILURE' && <p className="text-white">{file.fileProccess.error}</p>}
                  {file.fileProccess.state === 'RETRY' && <p className="text-white">{file.fileProccess.status}</p>}
                </div>
              )}
              {file.fileProccess && file.fileProccess?.state == 'PROGRESS' && (
                <div className={`w-full h-fit p-1 rounded-t bg-blue-400`}>
                  <p className="flex flex-row gap-1 text-white">
                    <Loading variant="circle" size={24} color="info" />
                    Processing : {file.fileProccess.progress}
                  </p>
                </div>
              )}

              {file?.fileProccess?.state === 'PENDING' && (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              )}
              {file?.fileProccess?.state === 'STARTED' ||
                (file?.fileProccess?.state === 'PROGRESS' && file.fileProccess.progress && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress
                      variant="buffer"
                      value={parseFloat(file.fileProccess.progress.replace('%', ''))}
                      valueBuffer={parseFloat(file.fileProccess.progress.replace('%', '')) !== 100 ? +5 : 100}
                    />
                    {/* <BorderLinearProgress
                      variant="buffer"
                      value={parseFloat(file.fileProccess.progress.replace('%', ''))}
                    /> */}
                  </Box>
                ))}
              <div className="flex items-start gap-x-6 py-6 px-4 relative bg-gray-100">
                <div className="w-16 h-16 rounded-md">
                  <img src={FileIcon} alt="file" className="w-full h-full object-contain" />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="text-neutral-700 text-base font-normal leading-tight  max-sm:text-sm">{file.name}</p>
                  <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">Date : {file.date}</p>
                  <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">Type : {file.type}</p>
                  <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">Size : {file.size}</p>
                </div>

                {!isProgress && !file.fileProccess.state && (
                  <div className="absolute top-0 right-0 flex flex-row gap-1">
                    <IconButton aria-label="delete" onClick={() => onDeleteAddedFile(file.id)}>
                      <img src={TrashIcon} alt="" />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddedFiles;
