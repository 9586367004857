import { request } from 'src/core/api/axios';

export interface NotificationData {
  NotificationID: number;
  Type?: number;
  Title?: string;
  DateTime?: string;
  CreatedDateTime?: string;
  Description?: string;
  SourceDocID?: number;
  DocumentName?: string;
  DocTypeID?: number;
  Resolved?: boolean;
  Seen?: boolean;
  Sent?: boolean;
  UserID?: number;
  EmailID?: number;
  NoteID?: number;
}

export interface RecentNotification {
  result: { message: NotificationData[] };
  error_code: number;
}
export interface NewNotification {
  result: { message: NotificationData[] };
  error_code: number;
}

export interface FeedbackBody {
  datetimes: string[];
  method: number;
}
export interface NumberOfNotification {
  error_code: number;
  message: string;
  result: number;
}
export interface RecentNotificationBody {
  start_date?: string;
  end_date?: string;
  number_of_notifications?: number;
}
export interface RangeDate {
  start_date: string;
  end_date: string;
}

interface Respond {
  error_code: number;
  message: string;
}

export interface RespondDeleteNotification {
  error_code: number;
}

export async function fetchNotifications(): Promise<NewNotification | any> {
  try {
    const { data } = await request.get<NewNotification>('/fetch_notifications');
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function feedbackNotification(body: FeedbackBody): Promise<Respond | any> {
  try {
    const { data } = await request.post<Respond>('/feedback_on_notifications', body);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getNumberOfNotifications(
  start_date: string,
  end_date: string
): Promise<NumberOfNotification | any> {
  try {
    const { data } = await request.post<NumberOfNotification>('/get_number_of_notifications', { start_date, end_date });
    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function getRecentNotifications(body: RecentNotificationBody): Promise<RecentNotification | any> {
  try {
    const { data } = await request.post<RecentNotification>('/get_recent_notifications', body);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDetailsEmail(email_id: number): Promise<any> {
  try {
    const { data } = await request.get<any>(`/email-info-by-email-id/${email_id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteNotification(notifications: number[]): Promise<RespondDeleteNotification | string> {
  try {
    const { data } = await request.post<RespondDeleteNotification>(`/delete-notifications`, {
      notification_ids: notifications,
    });
    return data;
  } catch (error) {
    return 'Error Delete Notifications';
  }
}
