import { streamEventDataWithFetch } from './../../../core/api/axios';

import { useCallback, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { useAppContext } from 'src/core/contexts/_app.context';
import { formatFileSize, getCurrentFormattedTime } from 'src/core/utilities/general';
import { checkUserSubscription } from 'src/modules/General/api';
import { RespondFile, Task, analyzer, statusTask } from 'src/modules/Queries/api';
import { FilesSelected } from 'src/modules/Queries/containers/AddedFiles';
import { dataURLtoFile } from 'src/modules/Queries/utils/imageUtil';
interface RecentFile {
  id: number;
  name: string;
}
export interface SnackbarData {
  display: boolean;
  color: string;
  message: string;
}
export interface MessageRespond {
  id: number;
  docName: string;
  message: string;
  errorCode: number;
}
// type UploadedFiles = { id: number; name: string; files?: File | string; type: 'screenshot' | 'file' };
export type UploadedFiles = { id: number; name: string; files?: File; type: 'screenshot' | 'file' };

const useAddDcoument = () => {
  const { state, dispatch } = useAppContext();

  const { screenshotMode, photos, subscription, selectedDocuments, endProccess, files } = state || {};
  const [showSnackbar, setShowSnackbar] = useState<SnackbarData>({ display: false, message: '', color: 'error' });

  const [errorMessages, setErrorMessages] = useState<MessageRespond[]>([]);
  // const [files, setFiles] = useState<UploadedFiles[]>([]);
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });
  const [openRespondModal, setOpenRespondModal] = useState<boolean>(false);
  const [showClearHistory, setShowClearHistory] = useState<boolean>(false);

  const [note, setNote] = useState('');
  const [concatFiles, setConcatFiles] = useState(false);
  const [notifNeeded, setNotifNeeded] = useState(true);
  const [uploadedList, setUploadedList] = useState<{ id: number; name: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const lastFileId = files[files.length - 1]?.id || 0;
  const attachedFilesNames = attachedFiles.map((file) => file.name);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [messeageSubscription, setMessageSubscription] = useState<string>('');
  const navigate = useNavigate();

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleGoToSettings = () => {
    setIsDialogOpen(false);
    navigate('/settings');
  };
  const onCloseModal = () => setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  const onOpenModalRespond = () => setOpenRespondModal(true);

  const onCloseModalRespond = () => {
    setOpenRespondModal(false);
    setErrorMessages([]);
  };

  const onDrop = useCallback((files: File[]) => {
    if (subscription.subscription_status === 'expired_or_inactive') {
      setIsDialogOpen(true);
      setMessageSubscription('Please renew or subscribe to a plan!');
    } else {
      if (
        (subscription?.quotas && subscription?.quotas?.doc_length > 0 && subscription?.quotas?.total_free_pages > 0) ||
        subscription.subscription_status === ''
      ) {
        if (files?.length) {
          setAttachedFiles(Object.values(files));
          setOpenConfirmationModal({ type: 'file', open: true });
        }
      } else {
        setIsDialogOpen(true);
        setMessageSubscription("You've reached your limit of Upload file or Pages.");
      }
    }
  }, []);

  const onScreenshotClick = () => dispatch({ type: 'screenshotMode', value: !screenshotMode });
  const handleCloseSnackbar = () => setShowSnackbar({ display: false, message: '', color: 'error' });
  const onTakeScreenshot = (newLink: HTMLAnchorElement) => {
    {
      setLink(newLink);
      setOpenConfirmationModal({ type: 'screenshot', open: true });
    }
  };
  const convertFiles = (temp: UploadedFiles[]) => {
    const allFiles: FilesSelected[] = [];
    for (let i = 0; i < temp.length; i++) {
      const file = temp[i].files;
      allFiles.push({
        name: file?.name ?? 'myFile',
        type: file?.type ?? '',
        id: temp[i].id,
        date: getCurrentFormattedTime(),
        size: formatFileSize(file?.size ?? 0),
        fileProccess: {},
      });
    }

    dispatch({
      type: 'selectedDocuments',
      value: allFiles,
    });
  };
  const onConfirmAttachment = () => {
    {
      if (openConfirmationModal.type === 'screenshot') {
        // link?.download &&
        //   setFiles([...files, { id: lastFileId + 1, name: link?.download, files: link?.href, type: 'screenshot' }]);
      } else {
        let temp: UploadedFiles[] = [];

        for (let i = 0; i < attachedFiles.length; i++) {
          temp = [
            ...temp,
            { id: lastFileId + 1 + i, name: attachedFiles[i].name, files: attachedFiles[i], type: 'file' },
          ];

          if (endProccess === 'end') {
            const newFiles = temp;
            dispatch({ type: 'files', value: newFiles });
            convertFiles(newFiles);
            // setFiles(temp);
            setShowClearHistory(false);
            dispatch({ type: 'endProccess', value: 'no' });
            dispatch({ type: 'messageAnalyz', value: '' });
          } else {
            // setFiles([...files, ...temp]);
            const newFiles = [...files, ...temp];
            dispatch({ type: 'files', value: newFiles });
            convertFiles(newFiles);
          }
        }
        // convertFiles(files);
      }

      onCloseModal();
      setLink(null);
      setAttachedFiles([]);
    }
  };
  const addPhotos = () => {
    let temp: UploadedFiles[] = [];

    const allPhotos: File[] = [];
    for (let i = 0; i < photos.length; i++) {
      allPhotos.push(dataURLtoFile(photos[i] ?? '', `${new Date().getTime()}.jpeg`));
    }
    for (let j = 0; j < allPhotos.length; j++) {
      temp = [...temp, { id: lastFileId + 1 + j, name: allPhotos[j].name, files: allPhotos[j], type: 'file' }];
      dispatch({ type: 'files', value: [...files, ...temp] });
    }
    dispatch({ type: 'openCamera', value: false });
    dispatch({ type: 'clearPhotos' });
  };
  const clearPhotos = () => {
    dispatch({ type: 'clearPhotos' });
  };

  const onDeleteAddedFile = (fileId: number) => {
    const removedFiles = selectedDocuments.filter((file) => file.id !== fileId);
    dispatch({ type: 'selectedDocuments', value: removedFiles });
  };

  const onChangeNote = (note: string) => {
    setNote(note);
  };

  const onConcatFiles = (checked: boolean) => {
    setConcatFiles(checked);
  };

  const onNotifNeeded = (checked: boolean) => {
    setNotifNeeded(checked);
  };

  const onUploadFiles = async () => {
    if (subscription.subscription_status === 'expired_or_inactive') {
      setShowSnackbar({
        display: true,
        message: 'Please renew or subscribe to a plan! ',
        color: 'error',
      });
    } else {
      if (subscription?.quotas && subscription?.quotas?.doc_length > 0 && subscription?.quotas?.total_free_pages > 0) {
        const uploadFiles = files.map((file) => file.files) as File[];
        const uploadFilesNames = files.map((file) => file.name) as string[];
        const localeDate = getCurrentFormattedTime();
        setIsLoading(true);
        dispatch({
          type: 'isProgress',
          value: true,
        });
        dispatch({
          type: 'endProccess',
          value: 'no',
        });

        dispatch({
          type: 'messageAnalyz',
          value: 'We are analyzing your files. Please wait.',
        });
        try {
          const result = await analyzer({
            files: uploadFiles,
            concatenate: concatFiles,
            files_names: uploadFilesNames,
            note_by_user: note,
            notif_needed: notifNeeded,
            local_user_datetime: localeDate,
          });
          if (typeof result !== 'string') {
            setNotifNeeded(true);
            dispatch({
              type: 'statusUpload',
              value: result?.message ?? 'Error Proccessing Files !',
            });
            dispatch({
              type: 'messageAnalyz',
              value: `Processing started. This takes approximately ${convertSecondsToHoursAndMinutes(result?.estimation_time).hours > 0 ? convertSecondsToHoursAndMinutes(result?.estimation_time).hours + 'h ' : ''} ${convertSecondsToHoursAndMinutes(result?.estimation_time).minutes > 0 ? convertSecondsToHoursAndMinutes(result?.estimation_time).minutes + 'm ' : ''}${convertSecondsToHoursAndMinutes(result?.estimation_time).seconds > 0 ? convertSecondsToHoursAndMinutes(result?.estimation_time).seconds + 's' : ''}`,
            });

            setIsLoading(false);
            const allDocs = [...selectedDocuments];
            const taskIds = result?.tasks_info ?? [];
            const totalPage = result?.total_page;
            const changeDocs: FilesSelected[] = [];
            if (concatFiles) {
              for (let i = 0; i < allDocs.length; i++) {
                allDocs[i].fileProccess.state = 'PENDING';
                // for (let j = 0; j < taskIds.length; j++) {
                // if (allDocs[i].name === taskIds[j].file_name) {
                allDocs[i].fileProccess.taskId = taskIds[0].task_id;
                changeDocs.push(allDocs[i]);
                //   }
                // }
              }
            } else {
              for (let i = 0; i < allDocs.length; i++) {
                allDocs[i].fileProccess.state = 'PENDING';
                for (let j = 0; j < taskIds.length; j++) {
                  if (allDocs[i].name === taskIds[j].file_name) {
                    allDocs[i].fileProccess.taskId = taskIds[j].task_id;
                    changeDocs.push(allDocs[i]);
                  }
                }
              }
            }

            await dispatch({
              type: 'selectedDocuments',
              value: changeDocs,
            });

            const urls: string[] = [];
            for (let i = 0; i < taskIds.length; i++) {
              await urls.push(taskIds[i].task_id);
            }
            streamMultipleEventsConcurrently(urls, totalPage);
          } else {
            setIsLoading(false);
            setShowSnackbar({ display: true, message: result, color: 'error' });
            dispatch({
              type: 'messageAnalyz',
              value: result,
            });
            dispatch({
              type: 'isProgress',
              value: false,
            });
            dispatch({
              type: 'endProccess',
              value: 'end',
            });
          }
        } catch {
          dispatch({
            type: 'isProgress',
            value: false,
          });
          setShowSnackbar({ display: true, message: 'An error occurred while parsing the file!', color: 'error' });
        }

        const userSubscription = await checkUserSubscription();
        if (typeof userSubscription !== 'string') {
          dispatch({ type: 'subscription', value: userSubscription });
        }
      } else {
        dispatch({
          type: 'isProgress',
          value: false,
        });
        setShowSnackbar({
          display: true,
          message: "You've reached your limit of Upload file or Pages. Upgrade for unlimited access. ",
          color: 'error',
        });
      }
    }
  };

  const streamMultipleEventsConcurrently = async (urls: string[], totalPage: number) => {
    const promises = urls.map(
      (url) =>
        new Promise<void>((resolve, reject) => {
          streamEventDataWithFetch(
            'https://test-deeddex.eastus2.cloudapp.azure.com/sse/status_task/' + url + '?total_page=' + totalPage,
            (data) => {
              try {
                const allDocs = [...selectedDocuments];
                const jsonData = JSON.parse(data);
                for (let i = 0; i < allDocs.length; i++) {
                  if (allDocs[i].fileProccess.taskId === url) {
                    allDocs[i].fileProccess = jsonData;
                    allDocs[i].fileProccess.taskId = url;
                  }
                  dispatch({
                    type: 'selectedDocuments',
                    value: allDocs,
                  });
                }
              } catch (error) {
                console.error(`Error parsing JSON from ${url}:`, error);
                dispatch({
                  type: 'isProgress',
                  value: false,
                });

                setShowClearHistory(true);
              }
            },
            (error) => {
              console.error(`Error from ${url}:`, error);
              dispatch({
                type: 'isProgress',
                value: false,
              });
              convertFiles(files);

              setShowClearHistory(true);
              reject(error);
            }
          )
            .then(() => {
              resolve();
              dispatch({
                type: 'endProccess',
                value: 'end',
              });
              setShowClearHistory(true);
            })
            .catch((err) => reject(err));
        })
    );

    try {
      await Promise.all(promises);
      dispatch({
        type: 'endProccess',
        value: 'end',
      });
      console.log('All streams completed successfully');
      dispatch({
        type: 'isProgress',
        value: false,
      });
      dispatch({
        type: 'messageAnalyz',
        value: `Processing of all files has been completed.`,
      });
      setShowClearHistory(true);
    } catch (error) {
      console.error('Error in one of the streams:', error);
      dispatch({
        type: 'isProgress',
        value: false,
      });
      dispatch({
        type: 'endProccess',
        value: 'end',
      });
      setShowClearHistory(true);
    }
  };

  const convertSecondsToHoursAndMinutes = (decimal: number) => {
    const totalSeconds = Math.floor(decimal);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  };

  return {
    files,
    onDrop,
    onScreenshotClick,
    onTakeScreenshot,
    isModalOpen: openConfirmationModal.open,
    isModalRespondOpen: openRespondModal,
    onCloseModal,
    onCloseModalRespond,
    preview: link?.href || attachedFilesNames,
    onConfirmAttachment,
    onDeleteAddedFile,
    onChangeNote,
    onNotifNeeded,
    notifNeeded,
    onConcatFiles,

    onUploadFiles,
    uploadedList,
    isLoading,
    showSnackbar,
    handleCloseSnackbar,
    errorMessages,
    photos,
    clearPhotos,
    addPhotos,
    subscription,
    isDialogOpen,
    handleClose,
    handleGoToSettings,
    messeageSubscription,
    showClearHistory,
    setShowClearHistory,
    // setFiles,
    selectedDocuments,
  };
};

export default useAddDcoument;
