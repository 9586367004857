import { useEffect } from 'react';

interface CurrentPlanProps {
  interactive?: number;
  document_retrieval?: number;
  users?: number;
  email_accounts?: number;
  total_free_pages?: number;
  doc_length?: number;
  num_primary_email_msgs?: number;
  subscription_period_end?: number;
}

const CurrentPlan = ({
  interactive,
  document_retrieval,
  doc_length,
  email_accounts,
  num_primary_email_msgs,
  users,
  total_free_pages,
  subscription_period_end,
}: CurrentPlanProps) => {
  const convertTimestampToDateTime = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  return (
    <div className="w-full flex flex-col gap-6">
      {/* Header Section */}
      <div className="w-full h-auto flex flex-wrap justify-between items-center gap-4 mb-3">
        <div className="flex flex-col md:flex-row md:items-center gap-2">
          <div className="text-[#0c0c0c] text-xl font-bold leading-[38px]">Your Current Plan:</div>
          {subscription_period_end && subscription_period_end > 0 && (
            <div className="text-[#0c0c0c] text-sm font-bold leading-relaxed p-2 bg-green-300 rounded-lg">
              Your subscription is active for you until {convertTimestampToDateTime(subscription_period_end)}
            </div>
          )}
        </div>
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto  plan-table">
        <table className="w-full  border-[#ededed] border-2 mb-6">
          <thead>
            <tr className="text-[#0c0c0c] text-xs font-bold font-['Inter'] leading-snug bg-white border-b border-[#ededed] ">
              <th className="px-4 py-3 font-bold text-base">Users</th>
              <th className="px-4 py-3 font-bold text-base">Email Accounts</th>
              <th className="px-4 py-3 font-bold text-base">Primary Inbox Message Count</th>
              <th className="px-4 py-3 font-bold text-base">Document Length</th>
              <th className="px-4 py-3 font-bold text-base">Total Available Free Pages</th>
              <th className="px-4 py-3 font-bold text-base">Interactive</th>
              <th className="px-4 py-3 font-bold text-base">Document Retrieval</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-[#0c0c0c] text-sm font-normal font-['Inter'] leading-[16.80px]">
              <td className="px-6 py-4 font-bold text-center">{users}</td>
              <td className="px-6 py-4 font-bold text-center">{email_accounts}</td>
              <td className="px-6 py-4 font-bold text-center">{num_primary_email_msgs}</td>
              <td className="px-6 py-4 font-bold text-center">{doc_length}</td>
              <td className="px-6 py-4 font-bold text-center">{total_free_pages}</td>
              <td className="px-6 py-4 font-bold text-center">{interactive}</td>
              <td className="px-6 py-4 font-bold text-center">{document_retrieval}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurrentPlan;
