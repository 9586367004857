import { useState } from 'react';
import { useAppContext } from 'src/core/contexts/_app.context';
import { checkUserSubscription } from 'src/modules/General/api';
import { activeTrial } from 'src/modules/Settings/api';

const SettingsService = () => {
  const { dispatch } = useAppContext();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const setOpen = () => {
    setOpenDialog(true);
  };

  const ActiveTrialSubscription = async () => {
    try {
      setLoading(true);
      const activeSubscription = await activeTrial();
      setLoading(false);
      if (typeof activeSubscription !== 'string') {
        if (activeSubscription.error_code === 0) {
          setIsError(false);
          const userSubscription = await checkUserSubscription();
          setMessage('Your subscription has been activated.');
          if (typeof userSubscription !== 'string') {
            console.log(userSubscription);
            dispatch({ type: 'subscription', value: userSubscription });
          }
        } else {
          setIsError(true);
          setMessage(activeSubscription.detail ?? 'Your subscription could not be activated. Please try again.');
        }
      }
    } catch (error) {
      setIsError(true);
      setMessage('Your subscription could not be activated. Please try again.');
    }
  };
  return {
    setOpen,
    openDialog,
    closeDialog,
    ActiveTrialSubscription,
    loading,
    isError,
    message,
  };
};

export default SettingsService;
