import { useState, useEffect } from 'react';
import { resetPassword } from 'src/modules/General/api';
import Loading from 'src/modules/General/components/Loading';
import { useNavigate } from 'react-router-dom';
import Logo from 'src/modules/General/components/Logo';
import Title from 'src/modules/Authentication/components/Title';
import Input from 'src/modules/General/components/Input';
import Right from 'src/modules/Authentication/components/Right';
import { isStrongPassword } from 'src/core/utilities/validations';
import ResponsiveAppBar from '../AppBar';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccessMessage('');
    if (!isStrongPassword(password)) {
      setErrorMessage(
        'The password is invalid. Please ensure it includes at least one uppercase letter, one lowercase letter, one digit, one special character, and is at least 8 characters long.'
      );
      return;
    } else if (password !== confirmPassword) {
      setErrorMessage('Passwords mismatch.');
      return;
    }
    setIsLoading(true);
    try {
      const formData = {
        token: token!,
        new_password: password,
      };

      const resetPasswordResponse = await resetPassword(formData);
      setSuccessMessage(resetPasswordResponse.message);
    } catch (error) {
      console.log(error);
      setErrorMessage('An error occurred while trying to log in. Please try again.');
    }
    setIsLoading(false);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleSubmit(e);
  };

  const handleTogglePassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const backToLoginPage = () => {
    navigate('/user-login', {
      state: { successMessage: successMessage },
    });
  };

  useEffect(() => {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'token' parameter
    const token = urlParams.get('token');
    setToken(token);
  }, []);
  useEffect(() => {
    if (!token) {
      setErrorMessage('Please use an invite link to reset password.');
    } else {
      setErrorMessage('');
    }
  }, [token, password, confirmPassword]);
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-16">
        <ResponsiveAppBar />
      </div>
      <div className="w-full h-calc-64 flex flex-row pt-2 bg-white">
        <div className="w-5/12 flex items-center justify-center px-8 overflow-y-auto max-md:w-full max-sm:px-2">
          <div className="max-w-640 h-auto flex flex-col gap-y-8 max-xs:w-full">
            <Logo />
            <Title title="Forgot Password" />
            {!successMessage ? (
              <form onSubmit={handleSubmit} className="min-w-80 flex flex-col justify-start gap-y-4 max-xs:w-full">
                <Input
                  eyeIcon={true}
                  title="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  containerStyle="relative"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  handleKeyDown={handleKeyDown}
                  handleTogglePassword={handleTogglePassword}
                  placeholder="Enter New Password"
                  inputStyle="rounded-lg"
                />
                <Input
                  eyeIcon={true}
                  title="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  containerStyle="relative"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  handleKeyDown={handleKeyDown}
                  handleTogglePassword={handleToggleConfirmPassword}
                  placeholder="Enter Confirmed New Password"
                  inputStyle="rounded-lg"
                />
                {errorMessage && <p className="text-center text-red-600">{errorMessage}</p>}
                <button
                  disabled={isLoading}
                  type="submit"
                  className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
                  {isLoading && <Loading variant="circle" size={20} color="inherit" />}
                  Continue
                </button>
              </form>
            ) : (
              <div className="w-96 flex flex-col gap-y-7">
                <p className="text-center">{successMessage}</p>
                <button
                  onClick={backToLoginPage}
                  className="w-full h-11 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight">
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-7/12 block max-md:hidden">
          <Right />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
