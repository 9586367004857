import { useNavigate } from 'react-router-dom';
import Right from 'src/modules/Authentication/components/Right';
import Title from 'src/modules/Authentication/components/Title';
import Logo from 'src/modules/General/components/Logo';
import CheckIcon from 'src/assets/images/Queries/check.svg';
import { Helmet } from 'react-helmet';
const ResultRegister = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        {/* Google Tag Manager Script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16711098574"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16711098574');
          `}
        </script>
      </Helmet>
      <div className="w-full h-full flex flex-row bg-white">
        <div className="w-5/12 grid items-center justify-center p-8 overflow-y-auto max-md:w-full max-sm:px-2">
          <div className="max-w-640 h-auto flex flex-col gap-y-8 max-xs:w-full">
            <Logo />
            <Title title="Welcome to DeedDex" />

            <div className="border p-4 rounded shadow-xl">
              <div className="flex flex-row items-center justify-center">
                <div className="w-7 h-7 rounded-full bg-green-500 flex items-center justify-center">
                  <img src={CheckIcon} alt="check" className="w-4" />
                </div>
                &nbsp;
                <p className="text-lg font-bold">Account verification</p>
              </div>
              <div>
                <p className="p-4 mt-2 bg-orange-500 rounded-lg text-white font-bold">
                  You can only use the web and Android versions to purchase and activate a subscription.
                </p>
                <p className="text-black pt-3 leading-6">
                  Your registration was successful! Your account has been verified, and you can now access all our
                  features. We are thrilled to have you join our community. If you need any assistance, our support team
                  is always ready to help with any questions you may have.
                </p>
              </div>

              <button
                onClick={() => {
                  navigate('/user-login');
                }}
                className={`w-full h-11 mt-4 px-4 py-3 bg-primary rounded-xl shadow justify-center items-center flex flex-row gap-x-1 text-white text-base font-medium capitalize leading-tight disabled:bg-slate-300`}>
                Back to Login
              </button>
            </div>
          </div>
        </div>
        <div className="w-7/12 block max-md:hidden">
          <Right />
        </div>
      </div>
    </>
  );
};

export default ResultRegister;
