import { useAppContext } from 'src/core/contexts/_app.context';
import { Router } from 'src/core/router';
import cn from 'classnames';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import { checkUserSubscription } from '../../api';
import dayjs from 'dayjs';
import { fetchNotifications, RecentNotificationBody, getRecentNotifications } from 'src/modules/Notifications/api';

const Page = () => {
  const [call, setCall] = useState(false);
  const { dispatch, state } = useAppContext();
  const { screenshotMode, statusUpload, subscription } = state || {};
  const handleClose = () => {
    dispatch({ type: 'statusUpload', value: '' });
  };
  const checkSubscription = async () => {
    const userSubscription = await checkUserSubscription();
    if (typeof userSubscription !== 'string') {
      dispatch({ type: 'subscription', value: userSubscription });
    }
  };
  const getDefaultNotifications = async () => {
    const from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
    const to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());

    dispatch({ type: 'fromDate', value: dayjs(from.toLocaleDateString('en-CA')) });
    dispatch({ type: 'toDate', value: dayjs(to.toLocaleDateString('en-CA')) });
    try {
      await fetchNotifications();

      const body = {
        start_date: from.toLocaleDateString('en-CA'),
        end_date: to.toLocaleDateString('en-CA'),
        number_of_notifications: 20000,
      };

      setTimeout(() => {
        onGetNotificationsByDateRang(body);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const onGetNotificationsByDateRang = async (data: RecentNotificationBody) => {
    try {
      const allNotifications = await getRecentNotifications(data);

      dispatch({ type: 'notifications', value: allNotifications.result.message });
    } catch (error) {
      dispatch({ type: 'notifications', value: [] });

      console.error('Error fetching notifications by date range:', error);
    }
  };
  useEffect(() => {
    if (!call && localStorage.getItem('access_token') !== null) {
      checkSubscription();
      getDefaultNotifications();
      setCall(true);
    }
  });

  return (
    <div className={cn('app__container', 'h-full', screenshotMode && 'app__container--selected')}>
      {statusUpload !== '' && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={statusUpload !== ''}
          autoHideDuration={8000}
          onClose={handleClose}
          message={statusUpload}
          color="success"
        />
      )}
      {subscription.subscription_status != '' ||
      window.location.pathname === '/user-login' ||
      window.location.pathname === '/user-sign-up' ||
      window.location.pathname === '/plans' ||
      window.location.pathname === '/privacy-policy' ||
      window.location.pathname === '/direct-subscription' ||
      window.location.pathname === '/terms' ||
      window.location.pathname === '/user-forget-password' ||
      window.location.pathname === '/success' ||
      window.location.pathname === '/cancel' ||
      window.location.pathname === '/user-registered' ||
      window.location.pathname === '/user-register' ||
      window.location.pathname === '/user-reset-password' ||
      window.location.pathname === '/instuction-add-email' ? (
        <Router />
      ) : undefined}
    </div>
  );
};

export default Page;
