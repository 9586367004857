import ResponsiveAppBar from '../AppBar';

const Privacy = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-16">
        <ResponsiveAppBar />
      </div>
      <div className="w-full h-calc-64 overflow-y-auto p-6  bg-gray-50 text-neutral-800">
        <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-6">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-blue-700">DeedDex Privacy Policy</h1>
            <p className="text-sm text-gray-500">Effective Date: June 16, 2024</p>
          </div>

          <div className="flex flex-col justify-start gap-4">
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">1.Introduction and Legal Info </h2>
              <p className="text-sm leading-relaxed">
                This Privacy Policy applies to all the products, services, websites, and apps offered by DeedDex, except
                where otherwise noted. We refer to these collectively as the &quot;Services&quot; in this policy. The
                Services include but are not limited to DeedDex mobile and web applications.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">2.Scop </h2>
              <p className="text-sm leading-relaxed">
                Throughout this privacy policy, ‘Services’, ‘us’, ‘our’, and ‘we’ all refer to DeedDex. If you live in
                North America, DeedDex provides our Services to you under this privacy policy. We are committed to
                protecting your personal data and ensuring transparency about how we use it. This Privacy Policy
                explains how we handle data, including the information we use to offer our services. It covers what
                information we gather, how it impacts you, and how we protect your privacy by not storing your data and
                letting you control all your uploaded data.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">3.Information We Collect </h2>
              <p className="text-sm leading-relaxed">
                We support and receive or collect certain information to operate, enhance, understand, personalize,
                support, and promote our Services. This occurs when you install, access, or utilize them. The type of
                information we receive varies based on your use of our Services. Certain details are essential for us to
                provide our Services effectively. For example, you must provide your email address to create an account
                to use our Services. By utilizing the Services you are consenting to the collection, storage, and
                processing of all uploaded data.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">4.Withdrawal of Consent </h2>
              <p className="text-sm leading-relaxed">
                To withdraw consent and permanently delete all data stored during your use of the Services, you may
                follow the procedure outlined below. Due to the nature of the Services, withdrawal of consent
                necessarily results in our inability to provide the Services to you.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">5.Information You Provide. </h2>
              <p className="text-sm leading-relaxed">
                For clarity, the following lists depicts a sample of information that may be shared with DeedDex.
              </p>

              <ul>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Your Account Information : </strong>
                  To create a DeedDex account, you need to share your email (used as your username), password, first
                  name, and last name, without which you can&apos;t access the Services. Additional info like a personal
                  description is optional and can be added at any time after creating the account.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Your Uploaded Documents: </strong>
                  The uploaded documents are stored safely on secure cloud servers to manage your documents effectively.
                  Both the original documents and their processed versions are stored securely in our databases on cloud
                  platforms for as long as they are not deleted by yourself or via inactive account cleanups performed
                  by DeedDex.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Customer Support and Other Communications: </strong>
                  When you reach out to us for customer support or communicate with us, you might share information
                  about your use of our Services. This can include any information regarding your account, the document
                  you uploaded, and your contact details (e.g., an email address). For instance, you might email us
                  details about app performance or other concerns.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Your Emails Connected to DeedDex: </strong>
                  When you connect your email to DeedDex, information about your emails and the attachments are
                  automatically sent to DeedDex servers. This includes the senders’/receivers&apos; emails, date and
                  time, and the body of emails. Such data are processed and stored in our databases securely.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Your Questions or Search Terms: </strong>
                  The inputs to the DeedDex document retrieval or interactive search are stored on our cloud servers.{' '}
                </li>
              </ul>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">6.Automatically Collected Information </h2>
              <p className="text-sm leading-relaxed">
                For clarity, the following lists depicts a sample of information that may be shared with DeedDex.
              </p>

              <ul>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Usage and Log Information: </strong>
                  We gather details regarding your usage of our Services, including but not limited to service-related,
                  diagnostic, and performance data. This encompasses information about your activities (such as how you
                  use our Services and the time, frequency, and duration of your activities and interactions), log
                  files, and diagnostic, crash, logs and reports. Additionally, it includes information about when you
                  registered for our Services, which features you used, your payments history, or business functions,
                  and the last time you used our Services.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Device and Connection Information: </strong>
                  When you install, access, or use our Services, we gather information specific to your device and
                  connection. This includes details like app version, browser details, time zone, and IP address.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Correction of Information: </strong>
                  Should you become aware of erroneous or inaccurate information about you or your use of the Services,
                  you may correct said deficiencies by contacting us through the contract details provided below.
                </li>
              </ul>
            </section>
            <div className="text-sm text-black">
              <strong>7.How We Use Information </strong>
              <ul>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Our Services: </strong>
                  We utilize available information to run and deliver our Services. We also use information to
                  understand how individuals interact with our Services, enhance them, explore new features, and
                  troubleshoot. Additionally, we use your information to promptly address your inquiries and concerns.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Safety, Security, and Trustworthiness: </strong>
                  We prioritize safety, security, and trustworthiness within our Services. Utilizing available
                  information, we verify accounts and actions, address harmful behavior, shield users from negative
                  encounters and spam, and uphold safety and integrity across our platform. This includes investigating
                  suspicious activities or breaches of our terms and policies and ensuring lawful usage of our Services.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Communications About Our Services:</strong>
                  We use the information we have to communicate with you regarding our Services and to inform you about
                  our terms, policies, and important updates.
                </li>
              </ul>
            </div>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">8.Managing and Retaining Your Information </h2>
              <ul>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Access Your Documents: </strong>
                  You can use DeedDex Application or DeedDex web app to access your documents and email information such
                  as email bodies, senders, and receivers. The documents are available when using interactive search,
                  document retrieval, or via the recent files tab.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Safeguards. </strong>
                  We use industry standard protection measures in order to avoid any breach or unauthorized access to
                  your data. Our staff have been trained with regards to the aforementioned safeguards and may or may
                  not have access to your data solely for the purposes mentioned herein (i.e. the provision of the
                  Services).
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Deleting Your Documents and Your Account: </strong>
                  You can remove your uploaded documents or attachments from the servers by clicking on them and
                  selecting delete. You can also remove your account entirely to remove all the attached documents,
                  queries, and emails from our database. You can delete your account at any time, including if you wish
                  to withdraw your consent for us to use your information following applicable laws, by using the
                  &quot;delete my account&quot; feature in our application. When you delete your account, all your
                  documents and emails (including email history) will be removed from our servers, along with any other
                  information we no longer need to operate and provide our Services. Be aware that if you only delete
                  the application from your device without using the &quot;delete my account&quot; feature in our
                  application, your information will be stored with us for a longer period.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Data Storage and Deletion: </strong>
                  We ensure that your documents and parsed emails are securely stored. Once you delete your account
                  using the “delete my account” feature in our application, all your documents and parsed emails will be
                  permanently removed from our servers and will not be saved anywhere else. We prioritize your privacy
                  and ensure that your information is handled with the most care and security.
                </li>
              </ul>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">9.Law, Our Rights, and Protection </h2>
              <p className="text-sm leading-relaxed">
                We access, preserve, and share your information as described in the &quot;Information We Collect&quot;
                section of this Privacy Policy if we genuinely believe it is necessary to: (a) respond to applicable
                laws or regulations, legal processes, or government requests; (b) enforce our Terms and any other
                relevant terms and policies, including investigating potential violations; (c) detect, investigate,
                prevent, or address fraud, other illegal activities, or security and technical issues; or (d) protect
                the rights, property, and safety of our users and DeedDex, including preventing death or imminent bodily
                harm.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">10.Amendments and Notifications </h2>
              <ul>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Changes to This Policy: </strong>
                  We may update our Privacy Policy from time to time to reflect changes in our practices, technology,
                  legal requirements, or other factors. We encourage you to review this Privacy Policy periodically to
                  stay informed about how we are protecting the personal information we collect. The date this Privacy
                  Policy was last revised is identified at the top of the page as the &quot;Effective Date.&quot;
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Notification of Changes: </strong>
                  If we make any material changes to our Privacy Policy, we will notify you either through the email
                  address you have provided us, by placing a prominent notice on our website, or through direct
                  notification within the DeedDex application. This notification will be sent before the changes become
                  effective.
                </li>
                <li className="pl-3">
                  &#x2022; &nbsp; <strong>Continued Use: </strong>
                  Your continued use of our Services after the effective date of such changes means your acceptance of
                  the new terms of the Privacy Policy. If you do not agree to the changes, you have the option to delete
                  your account or cease using our Services.
                </li>
              </ul>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">11.Assignment, Change of Control, and Transfer </h2>
              <p className="text-sm leading-relaxed">
                If there&apos;s a merger, acquisition, bankruptcy, or sale of our assets, we&apos;ll share your data
                with the new owners or entities involved, following the applicable data protection laws.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">12.Contact Information </h2>
              <p className="text-sm leading-relaxed">
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us
                at:
              </p>

              <ul>
                <li className="pl-3">&#x2022; &nbsp; Email: policy@nexadeeds.com</li>
                <li className="pl-3">
                  &#x2022; &nbsp; Address: 220-3900, boul de la Côte-Vertu,Saint-Laurent,QCH4R 1V4
                </li>
              </ul>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">13.Children&apos;s Privacy </h2>
              <p className="text-sm leading-relaxed">
                Our Services are not intended for use by children under the age of 13 without parental consent. We do
                not knowingly collect personal data from children under 13 without such consent. If we become aware that
                we have collected personal data from a child under 13 without parental consent, we will take steps to
                delete such information promptly.
              </p>

              <p className="text-sm leading-relaxed">
                If you are a parent or guardian and wish to manage documents for your child under 13, you must provide
                explicit consent. This includes providing the child&apos;s personal information such as a passport for
                the sole purpose of document management within our Services.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">14.Third-Party Services </h2>
              <p className="text-sm leading-relaxed">
                We may use third-party services to help us provide and improve our Services. These third parties may
                have access to your information as necessary to perform their functions, but they are obligated not to
                disclose or use it for other purposes. We ensure that any third-party service providers comply with
                applicable data protection laws.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">15.Risks Associated with the Internet. </h2>
              <p className="text-sm leading-relaxed">
                Despite our best efforts to ensure that third parties will not access or obtain your personal
                information through your use of the Services, complete confidentiality and security cannot currently be
                guaranteed on the internet. Communication via the Internet is subject to interception, loss, or
                alteration. You acknowledge and agree that we cannot be held responsible for damages resulting from the
                transmission of confidential information or personal information over the internet and that such
                communications are at your own risk.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">16.Limitation of Liability. </h2>
              <p className="text-sm leading-relaxed">
                We, as well as our officers, directors, shareholders, owners, officials, partners, partnerships,
                principals, employees, affiliates and other related entities, servants, agents, representatives,
                successors and assigns, will not be held liable for any losses or damages (pecuniary or otherwise)
                resulting from the misuse of any information collected by any third party, or any misuse of any
                information collected through DeedDex not in violation of this Policy.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">17.Compliance with Privacy Laws. </h2>
              <p className="text-sm leading-relaxed">
                This Privacy Policy and our practices in general are designed to be in compliance with Canada’s Personal
                Information Protection and Electronic Documents Act (“PIPEDA”, S.C. 2000, c. 5) and Quebec’s An Act
                Respecting the Protection Of Personal Information in the Private Sector (R.S.Q. c. P-39.1). If you have
                any questions regarding this compliance, please contact us using the contact information further up in
                this Policy.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">18.International Data Transfers </h2>
              <p className="text-sm leading-relaxed">
                Your information may be transferred to and processed in countries other than your country of residence.
                These countries may have different data protection laws with which DeedDex may or may not comply with.
                For more information regarding compliance with non-Canadian legislation please reach out to the contact
                information provided above.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">19.User Rights </h2>
              <p className="text-sm leading-relaxed">
                You have the right to access, correct, delete, or restrict the processing of your personal data. To
                exercise these rights, please contact us using the contact information provided above.
              </p>
            </section>
            <section className="mb-6">
              <h2 className="text-lg font-semibold text-gray-700">20.Prohibited Content </h2>
              <p className="text-sm leading-relaxed">
                Uploading illegal documents, images, or any content that violates our terms of service is strictly
                prohibited. This includes, but is not limited to, documents and images that:
              </p>

              <ul>
                <li className="pl-3">&#x2022; &nbsp;Infringe on intellectual property rights</li>
                <li className="pl-3">&#x2022; &nbsp;Contain explicit, obscene, or pornographic material</li>
                <li className="pl-3">&#x2022; &nbsp;Promote violence, discrimination, or hate speech</li>
                <li className="pl-3">&#x2022; &nbsp;Include personal data without consent</li>
              </ul>
            </section>
            <footer className="mt-8 border-t pt-4 text-center">
              <p className="text-xs text-gray-500">
                We reserve the right to delete any such content and take appropriate action, including reporting to
                relevant authorities if necessary.
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
