import { Dayjs } from 'dayjs';
import { createContext, useContext, useReducer } from 'react';
import { UserSubscriptionDataModel } from 'src/modules/General/api';
import { NotificationData } from 'src/modules/Notifications/api';
import { FilesSelected } from 'src/modules/Queries/containers/AddedFiles';
import { UploadedFiles } from 'src/pages/Documents/Upload/index.services';

type StateType = {
  screenshotMode: boolean;
  photos: string[];
  files: UploadedFiles[];
  openCamera: boolean;
  endProccess: string;
  statusUpload: string;
  messageAnalyz: string;
  subscription: UserSubscriptionDataModel;
  selectedDocuments: FilesSelected[];
  notifications: NotificationData[];
  isProgress: boolean;
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
};

type ActionType =
  | { type: 'screenshotMode'; value: StateType['screenshotMode'] }
  | { type: 'photos'; value: StateType['photos'][number] }
  | { type: 'openCamera'; value: StateType['openCamera'] }
  | { type: 'isProgress'; value: StateType['isProgress'] }
  | { type: 'endProccess'; value: StateType['endProccess'] }
  | { type: 'fromDate'; value: StateType['fromDate'] }
  | { type: 'toDate'; value: StateType['toDate'] }
  | { type: 'statusUpload'; value: StateType['statusUpload'] }
  | { type: 'messageAnalyz'; value: StateType['messageAnalyz'] }
  | { type: 'selectedDocuments'; value: StateType['selectedDocuments'] }
  | { type: 'notifications'; value: StateType['notifications'] }
  | { type: 'files'; value: StateType['files'] }
  | { type: 'clearPhotos' }
  | { type: 'subscription'; value: StateType['subscription'] };

const initialState = {
  screenshotMode: false,
  files: [],
  photos: [],
  openCamera: false,
  endProccess: 'end',
  messageAnalyz: '',
  statusUpload: '',
  subscription: {
    active: false,
    user_id: 0,
    email: '',
    first_name: '',
    last_name: '',
    full_name: '',
    exp: 0,
    subscription_status: '',
    quotas: {
      interactive: 0,
      document_retrieval: 0,
      users: 0,
      email_accounts: 0,
      total_free_pages: 0,
      doc_length: 0,
      num_primary_email_msgs: 0,
    },
  },
  selectedDocuments: [],
  notifications: [],
  isProgress: false,
  fromDate: null,
  toDate: null,
};

const AppContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

const mainReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'screenshotMode': {
      return { ...state, screenshotMode: action.value };
    }
    case 'openCamera': {
      return { ...state, openCamera: action.value };
    }
    case 'isProgress': {
      return { ...state, isProgress: action.value };
    }
    case 'statusUpload': {
      return { ...state, statusUpload: action.value };
    }
    case 'photos': {
      return { ...state, photos: [...state.photos, action.value] };
    }
    case 'clearPhotos': {
      return { ...state, photos: [] };
    }
    case 'subscription': {
      return { ...state, subscription: action.value };
    }
    case 'selectedDocuments': {
      return { ...state, selectedDocuments: action.value };
    }
    case 'notifications': {
      return { ...state, notifications: action.value };
    }
    case 'files': {
      return { ...state, files: action.value };
    }
    case 'messageAnalyz': {
      return { ...state, messageAnalyz: action.value };
    }
    case 'endProccess': {
      return { ...state, endProccessz: action.value };
    }
    case 'fromDate': {
      return { ...state, fromDate: action.value };
    }
    case 'toDate': {
      return { ...state, toDate: action.value };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
};
