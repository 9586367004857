import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface UpgradeDialogProps {
  open: boolean;
  onClose: () => void;
  onUpgrade: () => void;
  message: string;
}

const UpgradeDialog: React.FC<UpgradeDialogProps> = ({ open, onClose, onUpgrade, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="text-center">Upgrade Now!</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onUpgrade} color="primary">
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
