import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { detectService } from '../utilities/apis';
import { setError } from 'src/modules/General/store/error-slice';
import { store } from 'src/_app';
import { refreshToken } from 'src/modules/General/api';

const client = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    env: process.env.REACT_APP_ENVIORMENT,
    Source: 'web',
  },
  timeout: 10800000, //3H
});

client.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

export function setAuthToken(tokenType: string, accessToken: string) {
  client.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.request?.responseURL == detectService('/refresh-token') && error?.response?.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      localStorage.removeItem('language');
      localStorage.removeItem('i18nextLng');
      window.location.reload();
      return;
    }

    if (error?.response?.request?.responseURL == detectService('/login')) {
      store.dispatch(setError({ message: error?.response?.data?.detail }));
      return;
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          const newTokens = await getAccessToken(localStorage.getItem('refresh_token') ?? '');

          if (newTokens) {
            client.defaults.headers.common['Authorization'] = `Bearer ${newTokens.access_token}`;
            processQueue(null, newTokens.access_token);

            originalRequest.headers['Authorization'] = `Bearer ${newTokens.access_token}`;
            return client(originalRequest);
          }
        } catch (err) {
          processQueue(err, null);
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (token: string) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(client(originalRequest));
            },
            reject: (err: any) => {
              reject(err);
            },
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

const getAccessToken = async (refresh_token: string) => {
  try {
    const loginRespond = await refreshToken(refresh_token);
    if (loginRespond === 'Invalid refresh token') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      window.location.reload();
      return null;
    }

    localStorage.setItem('access_token', loginRespond.access_token);
    localStorage.setItem('refresh_token', loginRespond.refresh_token);

    setAuthToken('Bearer', loginRespond.access_token);
    return loginRespond;
  } catch (error) {
    return null;
  }
};

export const request = {
  get: <T>(endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return client.get(detectService(endpoint), options);
  },
  post: <T>(endpoint: string, data: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return client.post(detectService(endpoint), data, options);
  },
  put: (endpoint: string, data: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.put(detectService(endpoint), data, options);
  },
  patch: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.patch(detectService(endpoint), options);
  },
  delete: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.delete(detectService(endpoint), options);
  },
  options: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.options(detectService(endpoint), options);
  },
  head: (endpoint: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return client.head(detectService(endpoint), options);
  },
};

export const streamEventDataWithFetch = async (
  url: string,
  onData: (data: string) => void,
  onError: (error: any) => void
) => {
  try {
    const token = localStorage.getItem('access_token');

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8', { stream: true } as TextDecoderOptions);

    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader!.read();
      done = readerDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');
        lines.forEach((line) => {
          if (line.startsWith('data:')) {
            const eventData = line.replace('data:', '').trim();
            onData(eventData);
          }
        });
      }
    }
  } catch (error) {
    onError(error);
  }
};
