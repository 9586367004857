import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import SuccessIcon from 'src/assets/images/ResultPayment/success.png';

const SuccessPage = () => {
  const navigate = useNavigate();
  const backToDeedDex = () => {
    navigate('/settings');
  };

  return (
    <>
      <Helmet>
        {/* Google Tag Manager Script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16711098574"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16711098574');
          `}
        </script>
        {/* Conversion Tracking Script */}
        <script>
          {`
            window.addEventListener('load', function() {
              if (window.location.pathname === '/success') {
                gtag('event', 'conversion', {'send_to': 'AW-16711098574/5_-0CL-bueQZEM7BvKA-'});
              }
            });
          `}
        </script>
      </Helmet>
      <div className="w-full h-full flex flex-col items-center justify-center bg-gradient-to-br from-blue-500 to-orange-500">
        <div className="p-8 flex flex-col items-center justify-center bg-white shadow-md rounded-lg gap-4 max-w-screen-sm">
          <img src={SuccessIcon} className="w-24 max-md:w-20 max-sm:w-16" alt="Success" />
          <h3 className="text-2xl max-md:text-lg font-semibold">Your subscription is now active.</h3>
          <p>
            The payment was successfully completed, and your subscription is now active. You can enjoy the exciting
            features of <b>DeedDex</b>.
          </p>
          <button className="p-4 bg-primary rounded-lg text-white" onClick={backToDeedDex}>
            Back To DeedDex
          </button>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
