import { useState } from 'react';

import { deleteNotification, feedbackNotification } from '../../api';
import { useAppContext } from 'src/core/contexts/_app.context';

const notificationService = () => {
  const { state, dispatch } = useAppContext();
  const { notifications } = state || {};
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeleteNotificationModal, setOpenDeleteNotificationModal] = useState<boolean>(false);
  const [notificationselectIds, setNotificationselectIds] = useState<number[]>([]);
  const [singleDelete, setSingleDelete] = useState<boolean>(false);

  const seenNotification = async (datetimes: string) => {
    const body = {
      datetimes: [datetimes, datetimes],
      method: 0,
    };
    const seen = await feedbackNotification(body);

    const data = [...notifications];

    for (let i = 0; i < data.length; i++) {
      if (data[i].DateTime === datetimes) {
        data[i].Seen = true;

        dispatch({ type: 'notifications', value: data });
        break;
      }
    }
  };
  const openDialogNotification = (dateTimes: string, seen: boolean) => {
    setOpen(true);
    if (!seen) {
      seenNotification(dateTimes);
    }
  };
  const openDialogDeleteNotification = (id: number) => {
    setOpenDeleteNotificationModal(true);
    setNotificationselectIds([id]);
    setSingleDelete(true);
  };
  const closeDeleteNotificationDialog = () => {
    setOpenDeleteNotificationModal(false);
  };
  const removeSingleNotification = async () => {
    setLoading(true);

    const data = await deleteNotification(notificationselectIds);

    if (typeof data !== 'string') {
      console.log(data);
    } else {
      console.log(data);
    }
    setLoading(false);
    setOpenDeleteNotificationModal(false);
    setSingleDelete(false);
  };

  return {
    openDialog: open,
    openDialogNotification,
    setOpen,
    openDeleteNotificationModal,
    closeDeleteNotificationDialog,
    openDialogDeleteNotification,
    loading,

    removeSingleNotification,
    singleDelete,
  };
};

export default notificationService;
