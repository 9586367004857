import { Divider } from '@mui/material';
import Loading from 'src/modules/General/components/Loading';
import Screenshot from 'src/modules/General/containers/Screenshot';
import SearchInput from 'src/modules/Queries/containers/SearchInput';
import useDocumentRetrieval from './index.services';
import PreviewModal from '../../../../modules/Queries/containers/PreviewModal/index';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelect from 'src/modules/Queries/components/MultiSelect';
import DocIcon from 'src/assets/images/Documents/file.svg';
import EmailIcon from 'src/assets/images/Emails/email.png';
import { useTranslation } from 'react-i18next';
import ContentEmailDialog from 'src/modules/Queries/containers/ContentEmailDialog';
import { EmailDataType } from 'src/modules/Queries/containers/ContentEmailDialog/index.types';
import Snackbar from 'src/modules/General/components/Snackbar';
import UpgradeDialog from 'src/modules/General/components/DialogUpgrade';
const DocumentRetrieval = () => {
  const {
    searchValue,
    searchResultOf,
    onSearchInputChange,
    onSendQuestion,
    lastScreenshotNumber,
    onTakeScreenshot,
    onConfirmAttachment,
    relatedDocuments,
    relatedEmails,
    showMessage,
    isLoadingQuestion,
    isModalOpen,
    onCloseModal,
    onPreviewDialog,
    showContentEmail,
    pdfData,
    changeTag,
    itemSelected,
    changeMethodSearch,
    methodSearch,
    reset,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    items,
    fromDateSelected,
    toDateSelected,
    tagsSelected,
    loadingDaownload,
    emailSelected,
    onRetry,
    openDialogEmail,
    closeDialogEmail,
    showSnackbar,
    setShowSnackbar,
    messeageSubscription,
    handleClose,
    handleGoToSettings,
    isDialogOpen,
  } = useDocumentRetrieval();
  const { t } = useTranslation();
  function reverseAndExtractAfterSeparator(input: string, separator: string): string | null {
    const reversedString = input.split('').reverse().join('');
    const parts = reversedString.split(separator);

    return parts.length > 1 ? parts[0].split('').reverse().join('') : null;
  }
  const documentsList = () => {
    return (
      <>
        {relatedDocuments.length > 0 && !showMessage && (
          <div className="w-full flex flex-col gap-3 bg-white p-4 rounded-lg shadow">
            <p className="flex flex-row items-center gap-2 text-lg font-semibold justify-center mb-4">
              {/* <img src={DocIcon} alt="Doc" className="w-9" /> */}
              Related Documents
            </p>
            <div className="w-full grid grid-cols-2 gap-2 max-sm:grid-cols-1 ">
              {relatedDocuments.map((document: { name: string; link: number }) => (
                <button
                  className="min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 flex justify-start items-center gap-6  text-neutral-700 text-base font-normal leading-tight cursor-pointer"
                  onClick={() => onPreviewDialog(document.link, document.name)}
                  key={document.link}>
                  <img src={DocIcon} alt="DocId" className="w-7" />
                  {document.name}
                </button>
              ))}
            </div>
          </div>
        )}
        {/* {showMessage && (
          <div className="p-3 border rounded-lg border-orange-500 self-start font-bold text-lg">{showMessage}</div>
        )} */}
      </>
    );
  };
  const emailsList = () => {
    return (
      <>
        {relatedEmails.length > 0 && !showMessage && (
          <div className="w-full flex flex-col gap-3 bg-white p-2 rounded-s-lg shadow">
            <p className="flex flex-row items-center gap-2 text-lg font-semibold justify-center mb-4">
              {/* <img src={EmailIcon} alt="Email" className="w-9" /> */}
              Related Emails
            </p>
            <div className="w-full grid grid-cols-2 gap-2 ">
              {relatedEmails.map((email: EmailDataType, index: number) => (
                <button
                  onClick={() => showContentEmail(email)}
                  className="min-h-16 px-4 py-6 bg-white rounded-2xl border border-gray-200 justify-start items-center gap-6 inline-flex text-neutral-700 text-base font-normal leading-tight cursor-pointer"
                  key={index}>
                  <img src={EmailIcon} alt="email" className="w-7" />
                  <span className="whitespace-nowrap overflow-hidden text-ellipsis">{email?.sender}</span>
                </button>
              ))}
            </div>
          </div>
        )}
        {/* {showMessage && (
          <div className="p-3 border rounded-lg border-orange-500 self-start font-bold text-lg">{showMessage}</div>
        )} */}
      </>
    );
  };

  return (
    <>
      <div className="flex items-center justify-center gap-4">
        <div className="w-full flex items-start flex-col gap-2">
          <div className="w-full grid grid-cols-2 gap-2 items-end justify-start">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label htmlFor="">{t('From')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <label htmlFor="">{t('To')}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    minDate={fromDate ?? undefined}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        style: { backgroundColor: 'white' },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <MultiSelect options={items} selectedOptions={itemSelected} onChange={changeTag} />
            </div>
          </div>
          <SearchInput
            disabledSearch={methodSearch === '' && fromDate === null && toDate === null && !itemSelected.length}
            value={searchValue}
            onChangeInput={onSearchInputChange}
            onIconClick={onSendQuestion}
            onMethodSearch={changeMethodSearch}
            resetDropDown={reset}
          />
          <div className="w-full flex flex-row items-center justify-end">
            <button
              onClick={onSendQuestion}
              disabled={
                (methodSearch === '' && fromDate === null && toDate === null && !itemSelected.length) ||
                searchValue === ''
              }
              className={`h-10 px-6 py-3   rounded-xl shadow justify-center items-center flex flex-row gap-x-1  text-base font-medium capitalize leading-tight bg-primary text-white disabled:bg-stone-100 disabled:text-black `}>
              {t('Search')}
            </button>
          </div>
        </div>
      </div>

      <Divider sx={{ borderColor: '#ffffff', width: '100%' }} />

      {searchResultOf && (
        <div>
          <div className="text-neutral-950 text-lg font-medium">Search Results for: {searchResultOf} </div>
          <div className="w-full flex flex-col items-center justify-start gap-1">
            {fromDateSelected && toDateSelected && (
              <p className="w-full">
                {t('Date')} : {fromDateSelected} - {toDateSelected}
              </p>
            )}
            {tagsSelected.length > 0 && (
              <p className="w-full flex flex-row justify-start gap-1">
                {' '}
                {t('Tags')} : {tagsSelected.map((tag) => tag)},
              </p>
            )}
          </div>
        </div>
      )}
      <div className="flex items-start justify-center flex-row">
        {!isLoadingQuestion ? (
          <div className="w-full h-full flex flex-col gap-4">
            {!(relatedDocuments.length > 0 && relatedEmails.length > 0) && showMessage !== '' && (
              <div className="p-3 border rounded-lg border-orange-500 self-start font-bold text-lg">{showMessage}</div>
            )}
            {relatedDocuments.length > 0 && documentsList()}
            {relatedEmails.length > 0 && emailsList()}
          </div>
        ) : (
          <Loading variant="circle" size={45} />
        )}
      </div>
      <Screenshot lastScreenshotNumber={lastScreenshotNumber} onTakeScreenshot={onTakeScreenshot} />
      <PreviewModal
        loading={loadingDaownload}
        open={isModalOpen}
        onClose={onCloseModal}
        pdfData={pdfData}
        onRetry={onRetry}
        text={t('Preview File')}
        buttons={[{ children: t('Close'), color: 'primary', variant: 'contained', onClick: onConfirmAttachment }]}
      />
      <ContentEmailDialog email={emailSelected} onClose={closeDialogEmail} open={openDialogEmail} />
      <Snackbar
        color={'error'}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message={messeageSubscription}
      />
      <UpgradeDialog
        onClose={handleClose}
        onUpgrade={handleGoToSettings}
        open={isDialogOpen}
        message={messeageSubscription}
      />
    </>
  );
};

export default DocumentRetrieval;
