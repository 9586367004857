import Header from 'src/modules/General/containers/Layout/Header';
import UserIcon from 'src/assets/images/Settings/user.svg';
import InfoIcon from 'src/assets/images/Settings/info-circle.svg';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Divider } from '@mui/material';
import Profile from './Profile';
import TablePlans from './Table';
import CurrentPlan from './CurrentPlan';
import PlanCard from './PlanCard';
import SettingsService from './index.services';
import LoginModal from 'src/modules/direct-subscription/components/loginModal';

const DirectSubscription = () => {
  const [userInfo, setUserInfo] = useState<any>();
  const [value, setValue] = useState('1');
  const [compareTab, setCompareTab] = useState('individual');
  const [selectedPlan, setSelectedPlan] = useState<string | null>('Free Trial');
  const [step, setStep] = useState<number>(1);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [questionAnswering, setQuestionAnswering] = useState<number>(2000);
  const [users, setUsers] = useState<number>(2000);
  const [pages, setPages] = useState<number>(1000);
  const [emails, setEmails] = useState<number>(1000);

  const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const changeStep = (step: number) => {
    setStep(step);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleCompareTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setCompareTab(newTab);
  };

  useEffect(() => {
    if (localStorage?.getItem('userData')) {
      setUserInfo(JSON.parse(localStorage?.getItem('userData') ?? ''));
    }
    // if (localStorage.getItem('active_trial') !== null && localStorage.getItem('active_trial') !== '') {
    //   setActiveSubscription(true);
    // } else {
    //   setActiveSubscription(false);
    // }
  }, []);
  const handlePlanSelection = (plan: string) => {
    setSelectedPlan(plan);
  };
  const { openDialog, closeDialog, setOpen } = SettingsService();
  return (
    <>
      <div className="w-full h-full  py-8 px-6">
        <div className="w-full h-full bg-white rounded-xl flex flex-col justify-start items-start gap-6 px-8 py-6">
          <div className="w-full h-32 flex justify-start items-center gap-4">
            <div className="flex flex-col justify-start items-start gap-2">
              <div className="text-[#0c0c0c] text-lg font-medium">
                {userInfo?.firstName + ' ' + userInfo?.lastName || 'User'}
              </div>
              <div className="text-neutral-700 text-base font-medium break-words">{userInfo?.email}</div>
            </div>
          </div>
          <div className="w-full h-full overflow-y-auto">
            <Divider />
            <div className="w-full py-6 flex flex-col justify-start items-center gap-8">
              {step === 1 && (
                <>
                  <p className="text-center text-[#0c0c0c] text-xl font-bold leading-snug md:text-2xl">
                    Compare our plans and find yours
                  </p>
                  <div className="w-full flex flex-wrap items-start justify-center gap-4 mb-5">
                    <PlanCard
                      name="Free Trial"
                      monthlyPrice="Free"
                      yearlyPrice="Free"
                      description="Try free plan for 14 days."
                      isSelected={selectedPlan === 'Free Trial'}
                      onSelect={() => handlePlanSelection('Free Trial')}
                    />
                    <PlanCard
                      name="Basic"
                      monthlyPrice="$4.99"
                      yearlyPrice="$29.99"
                      isSelected={selectedPlan === 'Individual-Basic'}
                      onSelect={() => {
                        console.log('');
                      }}
                    />
                    <PlanCard
                      name="Standard"
                      monthlyPrice="$14.99"
                      yearlyPrice="$125.99"
                      isSelected={selectedPlan === 'Individual-Standard'}
                      onSelect={() => {
                        console.log('');
                      }}
                    />
                    <PlanCard
                      name="Premium"
                      monthlyPrice="$29.99"
                      yearlyPrice="$287.99"
                      isSelected={selectedPlan === 'Individual-Premium'}
                      onSelect={() => {
                        console.log('');
                      }}
                    />
                    <PlanCard
                      name="Professional"
                      monthlyPrice="$49.99"
                      yearlyPrice="$479.99"
                      isSelected={selectedPlan === 'Individual-Professional'}
                      onSelect={() => {
                        console.log('');
                      }}
                    />
                  </div>

                  <div className="w-full p-4">
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-center items-center gap-3 mb-3">
                        {/* <div className="text-[#0066ff] text-xs font-bold">Overview</div> */}
                        {/* {!activeSubscription && ( */}
                        <button onClick={setOpen} className="h-12 px-6 py-3 bg-[#0066ff] rounded-xl text-white">
                          Active Free Trial
                        </button>
                        {/* )} */}
                      </div>
                      <div className="mb-4">
                        <TablePlans />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="w-full h-full flex flex-col gap-4">
                  <div className="flex flex-col items-start gap-4 mb-4">
                    <div className="flex items-end gap-1">
                      <div className="text-[#0c0c0c] text-lg font-medium">Selected Plan:</div>
                      <div className="text-[#202020] text-base font-medium">{selectedPlan}</div>
                    </div>
                  </div>
                  <Divider />
                  {/* Add other sections */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoginModal open={openDialog} onClose={closeDialog} />
    </>
  );
};

export default DirectSubscription;
