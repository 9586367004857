import { createContext, useContext, useEffect, useReducer } from 'react';
import { NotificationData } from 'src/modules/Notifications/api';
type StateType = {
  notifications: NotificationData[];
  loading: boolean;
};

type ActionType =
  | { type: 'notifications'; value: StateType['notifications'] }
  | { type: 'loading'; value: StateType['loading'] };

const initialState = {
  notifications: [],
  loading: false,
};

const NotificationsContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <NotificationsContext.Provider value={{ state, dispatch }}>{children}</NotificationsContext.Provider>;
};

export const useNotificationsContext = () => useContext(NotificationsContext);

const mainReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'notifications': {
      return { ...state, notifications: action.value };
    }
    case 'loading':
      return { ...state, loading: action.value };

    default: {
      throw Error('Unknown action: ' + action);
    }
  }
};
