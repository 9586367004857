import { useState } from 'react';
import { useAppContext } from 'src/core/contexts/_app.context';
import { useNotificationsContext } from 'src/core/contexts/notifications.context';
import {
  NotificationData,
  RecentNotificationBody,
  deleteNotification,
  fetchNotifications,
  getRecentNotifications,
} from 'src/modules/Notifications/api';

const useNotificationPage = () => {
  const { dispatch: dispatchNotification } = useNotificationsContext();
  const { state, dispatch } = useAppContext();
  const { notifications, fromDate, toDate } = state || {};
  const [notificationsList, setNotificationsList] = useState<NotificationData[] | null>(null);
  const [unRead, setUnRead] = useState<number>(0);
  const [read, setRead] = useState<number>(0);
  // const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  // const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [isLoadingData, setIsLodingDate] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [displaySnackbar, setDispalySnackbar] = useState<{ display: boolean; color: string; message: string }>({
    display: false,
    color: '',
    message: '',
  });
  const [openDeleteNotificationModal, setOpenDeleteNotificationModal] = useState<boolean>(false);
  const [openSingleDeleteNotificationModal, setOpenSingleDeleteNotificationModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const onGetNotificationsByDateRang = async (data: RecentNotificationBody) => {
    try {
      await fetchNotifications();
      setTimeout(async () => {
        dispatchNotification({ type: 'loading', value: true });
        setIsLodingDate(true);
        const allNotifications = await getRecentNotifications(data);

        dispatch({ type: 'notifications', value: allNotifications.result.message });

        setNotificationsList(allNotifications.result?.message);
        dispatchNotification({ type: 'loading', value: false });
        setIsLodingDate(false);
      }, 2000);
    } catch (error) {
      setIsLodingDate(false);
      dispatchNotification({ type: 'loading', value: false });
      console.error('Error fetching notifications by date range:', error);
      setNotificationsList([]);
    }
  };
  const getStatusNotifications = (notifications: NotificationData[]) => {
    if (notifications?.length) {
      const read = notifications.filter((notification: NotificationData) => {
        return notification.Seen === true;
      });
      const unRead = notifications.filter((notification: NotificationData) => {
        return notification.Seen === false;
      });
      setRead(read.length);
      setUnRead(unRead.length);
    } else {
      setRead(0);
      setUnRead(0);
    }
  };
  const openDialogDeleteNotification = (id: number) => {
    setOpenDeleteNotificationModal(true);
    setSelectedNotifications([id]);
  };
  const closeDeleteNotificationDialog = () => {
    setOpenSingleDeleteNotificationModal(false);
  };
  const openDialogMultiDeleteNotification = () => {
    setOpenDeleteNotificationModal(true);
  };
  const closeMultiDeleteNotificationDialog = () => {
    setOpenDeleteNotificationModal(false);
  };
  const handleCloseSnackbar = () => {
    setDispalySnackbar({ display: false, message: '', color: '' });
  };

  const removeNotification = async () => {
    setLoadingDelete(true);

    const data = await deleteNotification(selectedNotifications);
    if (typeof data !== 'string') {
      setDispalySnackbar({ display: true, message: 'Notes Deleted', color: 'success' });

      // if (fromDate && toDate) {
      //   const body: RecentNotificationBody = {
      //     start_date: fromDate?.format('YYYY-MM-DD') ?? '',
      //     end_date: toDate?.format('YYYY-MM-DD') ?? '',
      //     number_of_notifications: 20000,
      //   };
      //   onGetNotificationsByDateRang(body);
      // }
      const updatedNotifications = notifications.filter(
        (notification) => !selectedNotifications.includes(notification.NotificationID)
      );
      dispatch({ type: 'notifications', value: updatedNotifications });
      setSelectedNotifications([]);
    } else {
      setDispalySnackbar({ display: true, message: 'Delete Faild !', color: 'error' });
    }
    setLoadingDelete(false);
    setOpenDeleteNotificationModal(false);
  };
  return {
    onGetNotificationsByDateRang,
    notificationsList,
    read,
    unRead,
    getStatusNotifications,
    fromDate,
    // setFromDate,
    toDate,
    // setToDate,
    isLoadingData,
    selectedNotifications,
    setSelectedNotifications,
    selectAll,
    setSelectAll,
    removeNotification,
    openDeleteNotificationModal,
    openSingleDeleteNotificationModal,
    openDialogDeleteNotification,
    openDialogMultiDeleteNotification,
    closeDeleteNotificationDialog,
    closeMultiDeleteNotificationDialog,
    handleCloseSnackbar,
    displaySnackbar,
    loadingDelete,
    notifications,
  };
};

export default useNotificationPage;
