import { useLocation } from 'react-router-dom';

const useExcludePaths = () => {
  const { pathname } = useLocation();

  const excludePaths = [
    '/user-login',
    '/user-register',
    '/user-sign-up',
    '/user-forget-password',
    '/user-reset-password',
    '/privacy-policy',
    '/terms',
    '/instuction-add-email',
    '/direct-subscription',
    '/success',
    '/cancel',
    '/user-registered',
    '/plans',
  ];

  const isExludedPath = excludePaths.find((path) => path.includes(pathname));
  return !!isExludedPath?.length;
};

export default useExcludePaths;
