import Input from "src/modules/General/components/Input";

const Profile = (props:{firstName:string , lastName: string, email:string, username:string}) =>{
    return (
        <div className="w-full h-full flex-col justify-center items-start gap-6 inline-flex">
            <div className="w-full grid grid-cols-3 gap-6">
                <Input
                      title="First Name"
                      type="text"
                      id="firstName"
                      value={props?.firstName}
                      placeholder="First Name"
                      inputStyle="rounded-lg"
                      disable={true}
                    />
                <Input
                      title="Last Name"
                      type="text"
                      id="lastName"
                      value={props?.lastName}
                      placeholder="Last Name"
                      inputStyle="rounded-lg"
                      disable={true}
                    />
                <Input
                      title="Username"
                      type="text"
                      id="Username"
                      value={props?.username}
                      placeholder="Username"
                      inputStyle="rounded-lg"
                      disable={true}
                    />
            </div>
            <div className="w-full grid grid-cols-3">
                <Input
                      title="Email"
                      type="text"
                      id="Email"
                      value={props?.email}
                      placeholder="Email"
                      inputStyle="rounded-lg"
                      disable={true}
                    />
            </div>
        </div>
    )
}

export default Profile;
