import CheckGreenIcon from 'src/assets/images/Settings/check.png';
import CloseIcon from 'src/assets/images/Settings/close.png';
import React from 'react';

const TablePlans: React.FC = () => {
  const plans = [
    {
      name: 'Free Trial',
      features: '__',
      notification: false,
      multiLevelAccess: false,
      clientCloud: false,
      twoFactorAuth: false,
      interactive: 'Up to 10',
      documentRetrieval: '50',
      users: 1,
      emailAccounts: 1,
      emailMessages: 10,
      uploadSize: 10,
      freePages: 10,
      extraPages: '__',
    },
    {
      name: 'Basic',
      features: '__',
      notification: false,
      multiLevelAccess: false,
      clientCloud: false,
      twoFactorAuth: false,
      interactive: 'Up to 40',
      documentRetrieval: '100',
      users: 1,
      emailAccounts: 1,
      emailMessages: 100,
      uploadSize: 10,
      freePages: 100,
      extraPages: '$3 per 500 extra pages',
    },
    {
      name: 'Standard',
      features: '__',
      notification: true,
      multiLevelAccess: false,
      clientCloud: false,
      twoFactorAuth: false,
      interactive: 'Up to 150',
      documentRetrieval: '1,000',
      users: 1,
      emailAccounts: 3,
      emailMessages: 300,
      uploadSize: 30,
      freePages: '1,000',
      extraPages: '$3 per 500 extra pages',
    },
    {
      name: 'Premium',
      features: '__',
      notification: true,
      multiLevelAccess: false,
      clientCloud: false,
      twoFactorAuth: false,
      interactive: 'Up to 300',
      documentRetrieval: '2,000',
      users: 1,
      emailAccounts: 4,
      emailMessages: '1,000',
      uploadSize: 50,
      freePages: '2,000',
      extraPages: '$3 per 500 extra pages',
    },
    {
      name: 'Professional',
      features: '__',
      notification: true,
      multiLevelAccess: true,
      clientCloud: false,
      twoFactorAuth: true,
      interactive: 'Up to 600',
      documentRetrieval: 'Unlimited',
      users: 1,
      emailAccounts: 10,
      emailMessages: 'Unlimited',
      uploadSize: 200,
      freePages: '5,000',
      extraPages: '$3 per 500 extra pages',
    },
  ];

  const renderIcon = (isAvailable: boolean) => (
    <img className="w-6 m-auto" src={isAvailable ? CheckGreenIcon : CloseIcon} alt={isAvailable ? 'check' : 'close'} />
  );

  return (
    <div className="overflow-x-auto w-full">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100 text-sm border">
            <th className="border px-4 py-2">Plan Type</th>
            <th className="border px-4 py-2">Notification (Monthly)</th>
            <th className="border px-4 py-2">Two-Factor Authentication (Monthly)</th>
            <th className="border px-4 py-2">Interactive (Monthly)</th>
            <th className="border px-4 py-2">Document Retrieval (Monthly)</th>
            <th className="border px-4 py-2">Users</th>
            <th className="border px-4 py-2">Email Accounts</th>
            <th className="border px-4 py-2">Primary Email Messages (Monthly)</th>
            <th className="border px-4 py-2">Page length</th>
            <th className="border px-4 py-2">Free Pages (Monthly)</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan, index) => (
            <tr key={index} className="text-sm border text-center">
              <td className="px-4 py-2">{plan.name}</td>

              <td className="px-4 py-2">{renderIcon(plan.notification)}</td>
              <td className="px-4 py-2">{renderIcon(plan.twoFactorAuth)}</td>
              <td className="px-4 py-2">{plan.interactive}</td>
              <td className="px-4 py-2">{plan.documentRetrieval}</td>
              <td className="px-4 py-2">{plan.users}</td>
              <td className="px-4 py-2">{plan.emailAccounts}</td>
              <td className="px-4 py-2">{plan.emailMessages}</td>
              <td className="px-4 py-2">{plan.uploadSize}</td>
              <td className="px-4 py-2">{plan.freePages}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePlans;
